import { Fragment, useEffect, useState, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import firebase from "firebase/compat/app";
import { generateBusinessId } from "../Tools/generateBusinessId";
import SelectSubscription from "./SelectSubscription";
import { toast } from "react-toastify";
import SelectStartDate from "./SelectStartDate";
import dayjs from "dayjs";
import StopCard from "./StopCard";

export default function ServiceLocationAddSubscription(props) {
  const [open, setOpen] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const selectedBusiness = props.selectedBusiness;
  const selectedCustomer = props.selectedCustomer;
  const [selectedSubscription, setSelectedSubscription] = useState();
  const today = new Date().toISOString().split("T")[0];
  const [selectedDate, setSelectedDate] = useState(today);
  const [price, setPrice] = useState("0");
  const selectedServiceLocation = props.selectedServiceLocation;
  const db = firebase.firestore();
  const subscriptionDetails = props.subscriptionDetails;
  const [pausedSubscriptions, setPausedSubscription] = useState(
    props.pausedSubscriptions
  );
  const [allEmployees, setAllEmployees] = useState([]);
  const [stops, setStops] = useState([]);
  const [existsInActiveRoute, setExistsInActiveRoute] = useState(false);
  const [customTag, setCustomTag] = useState("");

  useEffect(() => {
    if (props.pausedSubscriptions) {
      setPausedSubscription(props.pausedSubscriptions);
    }
  }, [props.reload, props.pausedSubscriptions]);

  useEffect(() => {
    setOpen(props.open);
    if (props.open) {
      db.collection("Businesses")
        .doc(selectedBusiness)
        .collection("Workers")
        .get()
        .then((snapshot) => {
          let workers = [];
          for (let index = 0; index < snapshot.docs.length; index++) {
            const doc = snapshot.docs[index].data();
            workers.push(doc);
          }
          setAllEmployees(workers);
        });

      db.collection("Businesses")
        .doc(selectedBusiness)
        .collection("Subscriptions")
        .get()
        .then((snapshot) => {
          let subs = [];
          for (let index = 0; index < snapshot.docs.length; index++) {
            const doc = snapshot.docs[index].data();
            subs.push(doc);
          }

          setSubscriptions(subs);
        });

      db.collection("Businesses")
        .doc(selectedBusiness)
        .collection("Routes")
        .where("active", "==", true)
        .get()
        .then(async (routesSnapshot) => {
          const activeRouteId = routesSnapshot.docs[0].id;
          const checkIfExistsInActiveRoute = await db
            .collection("Businesses")
            .doc(selectedBusiness)
            .collection("Routes")
            .doc(activeRouteId)
            .collection("ServiceLocations")
            .doc(selectedServiceLocation.serviceLocationId)
            .get();

          setExistsInActiveRoute(checkIfExistsInActiveRoute.exists);
        });
    }
  }, [props.open]);

  const handleClose = () => {
    setStops([]);
    setSelectedSubscription();
    props.setOpen(false);
  };

  const handleSuccessAddRoute = () => {
    props.setOpen(false);
    props.handleReloadData();
  };

  function isNotPreviousDate(dateString) {
    const inputDate = dayjs(dateString);
    const today = dayjs();

    if (inputDate.isBefore(today, "day")) {
      return false;
    }

    return true;
  }

  const handleAddSubscription = async () => {
    console.log(stops);
    const dayCounts = {};

    const finalStops = stops
      .filter((a) => a.day !== "none")
      .filter((a) => a.employee !== "none");

    for (const stop of finalStops) {
      // Assuming stop.day is a string like 'Monday', 'Tuesday', etc.
      if (dayCounts[stop.day]) {
        // If the day already exists in our count, it's a duplicate
        toast.warning(`Duplicate day found: ${stop.day}`);
        return; // Stop execution or handle this case appropriately
      }
      // Otherwise, set the count to 1
      dayCounts[stop.day] = 1;
    }

    if (selectedSubscription && price > 0) {
      const prevDate = isNotPreviousDate(selectedDate);
      if (!prevDate) {
        toast.warn("Cannot choose date earlier than today!");
        return;
      }

      // I need to make sure this subscription is not already added.

      console.log(subscriptionDetails);
      console.log(selectedSubscription);

      const random4Digits = Math.floor(1000 + Math.random() * 9000);

      let subToEdit = selectedSubscription;
      subToEdit.subscriptionPrice = price;
      subToEdit.subscriptionStopId = generateBusinessId();
      subToEdit.subscriptionTitle =
        customTag.trim().length > 0
          ? selectedSubscription.subscriptionTitle + " - " + customTag
          : selectedSubscription.subscriptionTitle;

      db.collection("Customers")
        .doc(selectedCustomer.customerId)
        .update({ subscriptions: [selectedSubscription.subscriptionId] })
        .then(() => {
          db.collection("Customers")
            .doc(selectedCustomer.customerId)
            .collection("ServiceLocations")
            .doc(selectedServiceLocation.serviceLocationId)
            .update({
              subscriptions:
                firebase.firestore.FieldValue.arrayUnion(subToEdit),
            })
            .then(() => {
              firebase
                .auth()
                .currentUser.getIdToken()
                .then((token) => {
                  /*
                  fetch(
                    "https://us-central1-symbri-production.cloudfunctions.net/sendCustomerNotification",
                    {
                      method: "POST",
                      body: JSON.stringify({
                        token: token,
                        notificationData: {
                          hasApp: {
                            body: `The subscription ${selectedSubscription.subscriptionTitle} has been added to the service location ${selectedServiceLocation.name}.`,
                            title:
                              "New subscription added to service location!",
                          },
                          noApp: {
                            body: `The subscription ${selectedSubscription.subscriptionTitle} has been added to the service location ${selectedServiceLocation.name}. Download the app for more information!`,
                            title:
                              "New subscription added to service location!",
                          },
                        },
                        customerId: selectedCustomer.customerId,
                      }),
                    }
                  );

                  */

                  toast.success("Subscription added!");
                  handleSuccessAddRoute();
                });
            });
        });
    } else {
      toast.warning("All fields required!");
    }
  };

  useEffect(() => {
    // check the template for how many stops then create stop objects inside stops state
    if (selectedSubscription) {
      console.log(selectedSubscription.connectedStopTemplateId);
      console.log(props.selectedBusiness);
      db.collection("Businesses")
        .doc(props.selectedBusiness)
        .collection("RecurringStopTemplates")
        .doc(selectedSubscription.connectedStopTemplateId)
        .get()
        .then((documentSnapshot) => {
          const data = documentSnapshot.data();

          setPrice(selectedSubscription.subscriptionPrice);

          const stopCount = data.stopIntervalPerWeek;

          let stopsArr = [];

          for (let index = 0; index < stopCount; index++) {
            stopsArr.push({ day: "none", employee: "none" });
          }
          console.log(stopsArr);
          setStops(stopsArr);
        });
    }
  }, [selectedSubscription]);

  const handleProcessSelection = (index, type, value) => {
    console.log(index);
    let currentStops = stops;
    if (type === "employee") {
      currentStops[index].employee = value;
      setStops(currentStops);
    } else {
      currentStops[index].day = value;
      setStops(currentStops);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => console.log()}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            {" "}
                            Add Subscription{" "}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => handleClose()}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Get started by filling in the information below to
                            add a subscription to this service location.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Select Subscription{" "}
                              </label>
                              <div className="mt-1">
                                <SelectSubscription
                                  businessId={props.selectedBusiness}
                                  setAddedSubscriptions={
                                    setSelectedSubscription
                                  }
                                  subscriptions={subscriptions}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Price{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  onChange={(change) =>
                                    setPrice(change.target.value)
                                  }
                                  type="number"
                                  value={price}
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10 pl-2"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Custom Subscription Tag{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  onChange={(change) =>
                                    setCustomTag(change.target.value)
                                  }
                                  value={customTag}
                                  placeholder="Enter tag name..."
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10 pl-2"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            {existsInActiveRoute && (
                              <div>
                                <label
                                  htmlFor="description"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Plan Stops{" "}
                                </label>
                                <div className="mt-4">
                                  {stops.map((stop, index) => (
                                    <StopCard
                                      index={index}
                                      allEmployees={allEmployees}
                                      handleProcessSelection={
                                        handleProcessSelection
                                      }
                                    />
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => handleClose(false)}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleAddSubscription}
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
