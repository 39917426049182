import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import firebase from "firebase/compat/app";
import HandleImportCustomers from "./HandleImportCustomers";
import { toast } from "react-toastify";
import { Rings } from "react-loader-spinner";

export default function ImportCustomers(props) {
  const [open, setOpen] = useState(false);
  const db = firebase.firestore();
  const selectedBusiness = props.businessId;
  const [currentStep, setCurrentStep] = useState(0);
  const [importData, setImportData] = useState([]);
  const [assignedFields, setAssignedFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [auditResults, setAuditResults] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [restrictedAccess, setRestrictedAccess] = useState(true);

  useEffect(() => {
    setOpen(props.open);
    if (!props.open) {
      setImportData([]);
      setAssignedFields([]);
      setCurrentStep(0);
      setLoading(false);
      setAuditResults(false);
      setRestrictedAccess(true);
      setEmail("");
      setPassword("");
    }
  }, [props.open]);

  const handleClose = () => {
    props.setOpen(false);
    setImportData([]);
    setAssignedFields([]);
    setCurrentStep(0);
    setLoading(false);
    setAuditResults(false);
    setRestrictedAccess(true);
    setEmail("");
    setPassword("");
  };

  const handleImportCustomersAudit = async () => {
    // check to make sure the selection subscription code is accurate
    if (currentStep === 1) {
      try {
        setLoading(true);

        const requiredFields = [
          "Subscription Code",
          "Full Name",
          "Cell Phone Number",
          "Email Address",
          "Service Location Address",
        ];

        for (let field of requiredFields) {
          if (!assignedFields.some((a) => a.mainField === field)) {
            toast.warn(`${field} field required!`);
            return;
          }
        }

        const token = await firebase.auth().currentUser.getIdToken();
        const response = await fetch(
          "https://us-central1-symbri-production.cloudfunctions.net/handleImportCustomersAudit",
          {
            method: "POST",
            body: JSON.stringify({
              importData: importData,
              token: token,
              selectedBusiness: { id: selectedBusiness },
              assignedFields: assignedFields,
              email: email,
            }),
          }
        );
        const data = await response.json().catch((e) => {
          toast.warn("Unknown error, please try again!");
          setLoading(false);
          return;
        });
        console.log(data);
        if (data.importErrors.length > 0) {
          toast.warn(
            `Audit failed, please correct errors sent to ${email} and try again!`
          );
          setAuditResults(false);
        } else {
          toast.success("Audit passed. Click import to complete the process.");
          setAuditResults(true);
        }

        setLoading(false);
        //handleClose();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleImportCustomers = async () => {
    // check to make sure the selection subscription code is accurate
    if (currentStep === 1) {
      try {
        setLoading(true);

        const requiredFields = [
          "Subscription Code",
          "Full Name",
          "Cell Phone Number",
          "Email Address",
          "Service Location Address",
        ];

        for (let field of requiredFields) {
          if (!assignedFields.some((a) => a.mainField === field)) {
            toast.warn(`${field} field required!`);
            return;
          }
        }

        const token = await firebase.auth().currentUser.getIdToken();
        const response = await fetch(
          "https://us-central1-symbri-production.cloudfunctions.net/handleImportCustomers",
          {
            method: "POST",
            body: JSON.stringify({
              importData: importData,
              token: token,
              selectedBusiness: { id: selectedBusiness },
              assignedFields: assignedFields,
            }),
          }
        );
        const data = await response.json().catch((e) => {
          toast.warn("Unknown error, please try again!");
          setLoading(false);
          return;
        });
        console.log(data);
        if (data.importErrors.length > 0) {
          for (let index = 0; index < data.importErrors.length; index++) {
            const importError = data.importErrors[index];
            toast.warning(
              `${importError.email} - ${importError.error.message}`,
              { autoClose: false }
            );
          }
        }
        setLoading(false);
        handleClose();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleStartImport = async () => {
    if (password === "X9$f1Q!rZ2^jL7#K") {
      if (email.trim() === "") {
        toast.warn("Please enter an email address for the audit report.");
        return;
      }
      setRestrictedAccess(false);
    } else {
      toast.warn("Invalid password, please try again!");
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => console.log()}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            {" "}
                            Import Customers{" "}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => handleClose()}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Get started importing customer by completing the
                            form below.
                          </p>
                        </div>
                      </div>
                      {restrictedAccess && (
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div className="flex flex-1 flex-col justify-between">
                              <div>
                                <label
                                  htmlFor="description"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Import Password
                                </label>
                                <div className="mt-1">
                                  <input
                                    onChange={(change) =>
                                      setPassword(change.target.value)
                                    }
                                    type="password"
                                    className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                  />
                                </div>
                              </div>
                              <div className="mt-4">
                                <label
                                  htmlFor="description"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Import Audit Email
                                </label>
                                <div className="mt-1">
                                  <input
                                    onChange={(change) =>
                                      setEmail(change.target.value)
                                    }
                                    type="text"
                                    className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {!restrictedAccess && (
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-200 px-4 sm:px-6">
                            <HandleImportCustomers
                              setCurrentStep={setCurrentStep}
                              currentStep={currentStep}
                              setImportData={setImportData}
                              importData={importData}
                              setAssignedFields={setAssignedFields}
                              assignedFields={assignedFields}
                            />
                          </div>
                        </div>
                      )}
                      {!restrictedAccess && (
                        <div
                          style={{
                            fontSize: 10,
                            width: "100%",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <div style={{ width: "88%", marginTop: 12 }}>
                            Pursuant to the regulations set forth by the
                            Telephone Consumer Protection Act (TCPA), it is
                            imperative that written consent is obtained from
                            your clientele before engaging in any form of text
                            messaging. By clicking the import button below, you
                            are explicitly acknowledging and affirming your
                            utilization of the Symbri software for the purpose
                            of transmitting text messages to your customers,
                            while affirming further that you possess the
                            requisite prior consent to engage in such textual
                            communication.
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </button>

                      {restrictedAccess && (
                        <button
                          onClick={handleStartImport}
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Start Import
                        </button>
                      )}

                      {currentStep === 1 && (
                        <button
                          onClick={
                            auditResults
                              ? handleImportCustomers
                              : handleImportCustomersAudit
                          }
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          {loading ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div>Loading </div>
                              <Rings
                                height={22}
                                width={22}
                                color="white"
                                arialLabel="loading-indicator"
                              />
                            </div>
                          ) : auditResults ? (
                            "Import"
                          ) : (
                            "Audit Import"
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
