import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import dayjs from "dayjs";

export default function CompanyCustomerGrowth(props) {
  const [growthData, setGrowthData] = useState([]);
  const db = firebase.firestore();

  const calculateMetrics = async () => {
    // Get the last 6 months in "MMMM-YYYY" format
    const last6Months = Array.from({ length: 6 }, (_, i) => {
      return dayjs().subtract(i, "month").format("MMMM-YYYY");
    }).reverse(); // Reverse the array to get the newest date on the right

    let stats = [];

    // Loop through the last 6 months and fetch data from Firestore
    for (let index = 0; index < last6Months.length; index++) {
      const key = last6Months[index];
      const businessMetricsDoc = await db
        .collection("Businesses")
        .doc(props.businessId)
        .collection("Metrics")
        .doc(key)
        .get();

      // If data exists for the month, add it to the stats array; otherwise, add zero
      if (businessMetricsDoc.exists) {
        const data = businessMetricsDoc.data();
        stats.push({ name: key, value: data.customerCount });
      } else {
        stats.push({ name: key, value: 0 });
      }
    }
    setGrowthData(stats); // Set the growth data for the chart
  };

  useEffect(() => {
    calculateMetrics();
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-0">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg border-gray-200 border-2 h-[400px]">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Customer Growth
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white pt-8">
                  <tr>
                    <td className="px-4 py-4">
                      {/* Recharts Line chart rendered here */}
                      <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={growthData}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis tickFormatter={(value) => `${value}`} />
                          <Tooltip formatter={(value) => `${value}`} />
                          <Legend />
                          {/* Render a single line for customer growth */}
                          <Line
                            type="monotone"
                            dataKey="value"
                            stroke="#8884d8"
                            strokeWidth={2}
                            dot={{ r: 3 }}
                            activeDot={{ r: 5 }}
                            name="Total Customers"
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
