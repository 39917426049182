/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import firebase from "firebase/compat/app";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SelectServiceLocation(props) {
  const [selected, setSelected] = useState("");
  const [options, setOptions] = useState([]);
  const db = firebase.firestore();

  const handleLocations = async () => {
    try {
      // Sort service locations
      const serviceLocations = props.allServiceLocations.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      let tempOptions = [];
      const fetchPromises = [];

      for (let index = 0; index < serviceLocations.length; index++) {
        let location = serviceLocations[index];
        const subs = location.subscriptions;
        if (!subs) continue;

        for (let i = 0; i < subs.length; i++) {
          const sub = subs[i];
          const connectedStopTemplateId = sub.connectedStopTemplateId;

          // Collect all promises to fetch connected stop templates
          fetchPromises.push(
            db
              .collection("Businesses")
              .doc(location.businessId)
              .collection("RecurringStopTemplates")
              .doc(connectedStopTemplateId)
              .get()
              .then((connectedStopTemplate) => {
                const connectedStopTemplateData = connectedStopTemplate.data();

                // Construct the new location object
                let newLocation = {
                  ...location,
                  recurringStopType: connectedStopTemplateData,
                  subscriptions: sub,
                };

                newLocation.name =
                  location.name + " - " + sub.subscriptionTitle;
                tempOptions.push(newLocation);
              })
          );
        }
      }

      // Wait for all Firestore queries to complete
      await Promise.all(fetchPromises);

      // Set options once all data is fetched
      console.log(tempOptions);
      setOptions(tempOptions);
    } catch (error) {
      console.error("Error handling locations: ", error);
    }
  };

  useEffect(() => {
    handleLocations();
  }, [props.allServiceLocations]);

  const handleChange = (value) => {
    props.setSelectedServiceLocation(value);
    setSelected(value);
  };

  return (
    <Listbox value={selected} onChange={handleChange}>
      {({ open }) => (
        <>
          <div className="mt-1 relative w-full">
            <Listbox.Button className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10 text-left pl-2">
              <span className="block truncate">{selected.name}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.uid}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "cursor-default select-none relative py-2 pl-3 pr-9"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {option.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
