import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import ServiceLocations from "./ServiceLocations";
import CustomerAddButton from "./CustomerAddButton";
import Jobs from "./Jobs";
import CustomerAddJob from "./CustomerAddJob";
import ServiceLocationProfile from "./ServiceLocationProfile";
import { BiUser } from "react-icons/bi";
import CustomerAddServiceLocation from "./CustomerAddServiceLocation";
import { toast } from "react-toastify";
import dayjs from "dayjs";

export default function CustomerProfile(props) {
  const selectedCustomerData = props.selectedCustomerData;
  const [serviceLocations, setServiceLocations] = useState([]);
  const [selectedServiceLocation, setSelectedServiceLocation] = useState();
  const [email, setEmail] = useState(selectedCustomerData.customerEmailAddress);
  const [jobs, setJobs] = useState([]);
  const [addServiceLocationDisplay, setAddServiceLocationDisplay] =
    useState(false);
  const [serviceLocationProfileDisplay, setServiceLocationProfileDisplay] =
    useState(false);
  const [addJobDisplay, setAddJobDisplay] = useState(false);
  const db = firebase.firestore();
  const [name, setName] = useState(
    (
      selectedCustomerData.customerFirstName +
      " " +
      selectedCustomerData.customerLastName
    ).replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
  );

  useEffect(() => {
    db.collection("Customers")
      .doc(selectedCustomerData.customerId)
      .collection("ServiceLocations")
      .where("customerId", "==", selectedCustomerData.customerId)
      .onSnapshot((snapshot) => {
        console.log(selectedCustomerData.customerId);
        let locationsArray = [];
        for (let index = 0; index < snapshot.docs.length; index++) {
          const docData = snapshot.docs[index].data();
          locationsArray.push(docData);
        }
        setServiceLocations(locationsArray);
        console.log(locationsArray);
      });
  }, []);

  useEffect(() => {
    const databaseSnapshot = db
      .collection("Businesses")
      .doc(props.businessId)
      .collection("Jobs")
      .where("customerId", "==", selectedCustomerData.customerId)
      .onSnapshot((snapshot) => {
        let jobsArray = [];
        for (let index = 0; index < snapshot.docs.length; index++) {
          const jobDoc = snapshot.docs[index].data();
          jobsArray.push(jobDoc);
        }
        setJobs(jobsArray);
      });
    return () => databaseSnapshot();
  }, []);

  const handleAddNewToCustomer = (type) => {
    console.log(type);
    if (type === "Service Location") {
      setAddServiceLocationDisplay(true);
    } else {
      setAddJobDisplay(true);
    }
  };

  const handleViewServiceLocationClick = (value) => {
    setSelectedServiceLocation(value);
    setServiceLocationProfileDisplay(true);
    props.handleAddToBreadcrumb({
      name: value.address.split(",")[0],
      type: "serviceLocation",
      current: true,
    });
  };

  const handleDeleteServiceLocation = async (serviceLocation) => {
    console.log(serviceLocation);
    const token = await firebase.auth().currentUser.getIdToken();
    const res = await fetch(
      "https://us-central1-symbri-production.cloudfunctions.net/handleDeleteServiceLocation",
      {
        method: "POST",
        body: JSON.stringify({
          serviceLocationId: serviceLocation.serviceLocationId,
          customerId: serviceLocation.customerId,
          token: token,
        }),
      }
    );

    const json = await res.json();
    if (!json.success) {
      toast.warn(json.message);
    } else {
      toast.success("Service location has been deleted!");
    }
  };

  const handleEditEmail = (value) => {
    setEmail(value);
    db.collection("Customers").doc(selectedCustomerData.customerId).update({
      customerEmailAddress: value,
    });
  };

  const handleEditName = (value) => {
    setName(value);
    db.collection("Customers")
      .doc(selectedCustomerData.customerId)
      .update({
        customerFirstName: value.split(" ")[0],
        customerLastName: value.split(" ")[1],
      });
  };

  function numericStringMask(str, mask) {
    if (!mask) return str;

    const numeric = str.replaceAll(/[^\d]/g, "");

    let idx = 0;

    const formated = mask.split("").map((el) => {
      if (el === "#") {
        el = numeric[idx];
        idx++;
      }
      return el;
    });

    return formated.join("");
  }

  return !serviceLocationProfileDisplay ? (
    <>
      <div className="min-h-full">
        <main className="py-10 p-44">
          {/* Page header */}
          <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-full lg:px-8">
            <div className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <div className="relative">
                  <BiUser className="h-10 w-10 rounded-full" />
                  <span
                    className="absolute inset-0 shadow-inner rounded-full"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">
                  <input
                    style={{ color: "black", width: "80%" }}
                    onChange={(change) => handleEditName(change.target.value)}
                    value={name}
                  />
                </h1>
                <p className="text-sm font-medium text-gray-500">
                  Has been a customer since{" "}
                  {dayjs(
                    firebase.auth().currentUser.metadata.creationTime
                  ).format("MM/DD/YYYY")}
                  .
                </p>
              </div>
            </div>
            <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
              <CustomerAddButton
                businessId={selectedCustomerData.businessIds[0]}
                handleAddNewToCustomer={handleAddNewToCustomer}
              />
            </div>
          </div>

          <div className="mt-8 max-w-3xl mx-auto gap-6 sm:px-6 lg:max-w-full lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              {/* Description list*/}
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Phone Number
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {numericStringMask(
                            selectedCustomerData.customerPhoneNumber,
                            "(###) ###-####"
                          )}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Email Address
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <input
                            style={{ color: "black", width: "40%" }}
                            onChange={(change) =>
                              handleEditEmail(change.target.value)
                            }
                            value={email}
                          />
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </section>

              <section aria-labelledby="notes-title">
                <div className="divide-y divide-gray-200">
                  <ServiceLocations
                    handleViewServiceLocationClick={
                      handleViewServiceLocationClick
                    }
                    serviceLocations={serviceLocations}
                    handleDeleteServiceLocation={handleDeleteServiceLocation}
                  />
                </div>
                <div className="divide-y divide-gray-200 mt-10">
                  <Jobs businessId={props.businessId} jobs={jobs} />
                </div>
              </section>
            </div>
          </div>
        </main>
        <CustomerAddJob
          open={addJobDisplay}
          setOpen={setAddJobDisplay}
          businessId={props.businessId}
          serviceLocations={serviceLocations}
          selectedCustomer={selectedCustomerData}
        />
        <CustomerAddServiceLocation
          open={addServiceLocationDisplay}
          setOpen={setAddServiceLocationDisplay}
          businessId={props.businessId}
          serviceLocations={serviceLocations}
          selectedCustomer={selectedCustomerData}
        />
      </div>
    </>
  ) : (
    <ServiceLocationProfile
      jobs={jobs}
      businessId={props.businessId}
      selectedServiceLocation={selectedServiceLocation}
      selectedCustomer={selectedCustomerData}
      handleAddToBreadcrumb={props.handleAddToBreadcrumb}
    />
  );
}
