export default function JobNotes(props) {
  return (
    <ul role="list" className="divide-y divide-gray-200">
      {props.jobNotes.map((note) => (
        <li
          key={note.noteBody}
          style={{ border: "1px solid #D1D5DB", borderRadius: 5 }}
          className="py-4 flex items-center"
        >
          <div>
            <p className="text-sm font-medium text-gray-500 text-left ml-2">
              {note.noteBody}
            </p>
          </div>
          <div className="ml-auto mr-4">
            <p className="text-sm font-medium text-gray-500">
              {note.noteWho?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                letter.toUpperCase()
              )}
            </p>
            <p className="text-sm font-medium text-gray-500 text-right">
              {note.noteWhen.seconds
                ? new Date(note.noteWhen.seconds * 1000).toLocaleDateString()
                : note.noteWhen}
            </p>
          </div>
        </li>
      ))}
    </ul>
  );
}
