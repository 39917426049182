import { Fragment, useEffect, useState, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import firebase from "firebase/compat/app";
import { generateBusinessId } from "../Tools/generateBusinessId";
import SelectServiceLocation from "./SelectServiceLocation";
import { toast } from "react-toastify";
import SelectQuickCreate from "./SelectQuickCreate";
import Geocoder from "react-native-geocoding";
import dayjs from "dayjs";
import SelectCustomerSearch from "./SelectCustomerSearch";
import SelectEmployeeSearch from "./SelectEmployeeSearch";
import { Rings } from "react-loader-spinner";
import SelectCategory from "./SelectCategory";
import CheckBox from "../GlobalComponents/CheckBox";

export default function NewJob(props) {
  const [open, setOpen] = useState(false);
  const selectedBusiness = props.selectedBusiness;
  const [jobTitle, setJobTitle] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [selectedServiceLocation, setSelectedServiceLocation] = useState();
  const [serviceLocations, setServiceLocations] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [quickCreate, setQuickCreate] = useState({ name: "None" });
  const [stopName, setStopName] = useState("");
  const [stopDescription, setStopDescription] = useState("");
  const [stopDate, setStopDate] = useState("");
  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    title: "None",
    color: "none",
  });
  const db = firebase.firestore();
  const [loading, setLoading] = useState(false);
  const [showDescriptionInput, setShowDescriptionInput] = useState(false);
  const [showStopDescriptionInput, setShowStopDescriptionInput] =
    useState(false);

  useEffect(() => {
    setOpen(props.open);
    if (props.open) {
      db.collection("Customers")
        .where("businessIds", "array-contains", selectedBusiness)
        .get()
        .then((snapshot) => {
          let customersArray = [];
          for (let index = 0; index < snapshot.docs.length; index++) {
            const customerDoc = snapshot.docs[index].data();
            customersArray.push(customerDoc);
          }
          setCustomers(customersArray);
        });

      db.collection("Businesses")
        .doc(selectedBusiness)
        .collection("JobCategories")
        .doc("Categories")
        .get()
        .then((snapshot) => {
          const data = snapshot.data();
          if (data) {
            setAllCategories(data.categories);
          }
        });

      db.collection("Businesses")
        .doc(selectedBusiness)
        .collection("Workers")
        .where("businessId", "==", selectedBusiness)
        .get()
        .then((snapshot) => {
          let employeesArray = [];
          for (let index = 0; index < snapshot.docs.length; index++) {
            const employeeDoc = snapshot.docs[index].data();
            employeesArray.push(employeeDoc);
          }
          setEmployees(employeesArray);
        });
    }
  }, [props.open]);

  useEffect(() => {
    if (selectedCustomer) {
      db.collection("Customers")
        .doc(selectedCustomer.customerId)
        .collection("ServiceLocations")
        .where("businessId", "==", selectedCustomer.businessIds[0])
        .get()
        .then((snapshot) => {
          let serviceLocationsArray = [];
          for (let index = 0; index < snapshot.docs.length; index++) {
            const serviceLocationDoc = snapshot.docs[index].data();
            serviceLocationsArray.push(serviceLocationDoc);
          }
          setServiceLocations(serviceLocationsArray);
          console.log(serviceLocationsArray);
        });
    }
  }, [selectedCustomer]);

  const handleClose = () => {
    setJobTitle("");
    setJobDescription("");
    setStopName("");
    setStopDescription("");
    setQuickCreate({ name: "None" });
    setShowDescriptionInput(false);
    setSelectedServiceLocation();
    setServiceLocations([]);
    setShowStopDescriptionInput(false);
    props.setOpen(false);
  };

  const handleAddJob = () => {
    if (
      (showDescriptionInput && jobDescription === "") ||
      (showDescriptionInput && jobDescription.trim().length === 0)
    ) {
      toast.warn("Job description field is required!");
      return;
    } else if (jobTitle === "" || jobTitle.trim().length === 0) {
      toast.warn("Job title field is required!");
      return;
    } else if (!selectedServiceLocation) {
      // need to also validate number format
      toast.warn("Job service location field is required!");
      return;
    }

    if (quickCreate.name === "Scheduled Stop") {
      // need to make sure that stopName etc are not empty
      if (stopName === "" || stopName.trim().length === 0) {
        toast.warn("Stop name field is required!");
        return;
      } else if (
        (showStopDescriptionInput && stopDescription === "") ||
        (showStopDescriptionInput && stopDescription.trim().length === 0)
      ) {
        toast.warn("Stop description field is required!");
        return;
      } else if (stopDate === "") {
        toast.warn("Stop date field is required!");
        return;
      } else if (!selectedEmployee) {
        toast.warn("Employee field is required!");
        return;
      }
    }

    if (quickCreate.name === "Unscheduled Stop") {
      if (stopName === "" || stopName.trim().length === 0) {
        toast.warn("Stop name field is required!");
        return;
      } else if (
        (showStopDescriptionInput && stopDescription === "") ||
        (showStopDescriptionInput && stopDescription.trim().length === 0)
      ) {
        toast.warn("Stop description field is required!");
        return;
      }
    }

    setLoading(true);
    const messageIdWithContractor = generateBusinessId();
    const newJobId = generateBusinessId();

    db.collection("Businesses")
      .doc(selectedBusiness)
      .get()
      .then((documentSnapshot) => {
        const data = documentSnapshot.data();
        const businessName = data.name;

        db.collection("Businesses")
          .doc(selectedBusiness)
          .collection("Jobs")
          .doc(newJobId)
          .set({
            status: "new",
            jobTitle: jobTitle,
            jobDescription: jobDescription,
            customerId: selectedCustomer.customerId,
            customerData: selectedCustomer,
            serviceLocation: selectedServiceLocation,
            jobId: newJobId,
            messageId: messageIdWithContractor,
            createdDate: dayjs().format("MM/DD/YYYY"),
            notes: [],
            stops: [],
            estimates: [],
            invoices: [],
            pics: [],
            messages: [],
            businessName: businessName,
            businessId: data.id,
            category: selectedCategory || "none",
          })
          .then(async () => {
            if (quickCreate.name === "Scheduled Stop") {
              const newStopId = generateBusinessId();

              db.collection("Businesses")
                .doc(selectedBusiness)
                .collection("Routes")
                .where("active", "==", true)
                .get()
                .then(async (routeQuery) => {
                  const routeId = routeQuery.docs[0].id;

                  Geocoder.from(selectedServiceLocation.address)
                    .then(async (json) => {
                      console.log(json.results[0].geometry.location);
                      const stopData = {
                        dayOfTheWeek: dayjs(stopDate).format("dddd"),
                        frequency: "once",
                        startOn: dayjs(stopDate).format("MM/DD/YYYY"),
                        stopAfter: "no end",
                        name: stopName,
                        address: selectedServiceLocation.address,
                        stopId: newStopId,
                        routeId: routeId,
                        serviceLocationId:
                          selectedServiceLocation.serviceLocationId,
                        completedDates: [],
                        contractor: {
                          id: selectedEmployee.uid,
                          name: selectedEmployee.name,
                          profilePhoto: selectedEmployee.profilePhoto,
                        },
                        customerData: {
                          name:
                            selectedCustomer.customerFirstName +
                            " " +
                            selectedCustomer.customerLastName,
                          customerId: selectedCustomer.customerId,
                          businessId: selectedBusiness,
                        },
                        businessId: selectedBusiness,
                        stopNickname: stopName,
                        location: json.results[0].geometry.location,
                        stopTemplateId: "none",
                        stopCustomerDescription: stopName,
                        stopWorkerDescription: stopDescription,
                        stopDate: dayjs(stopDate).format("MM/DD/YYYY"),
                        type: "Job Stop",
                        messageId: "none",
                        jobId: newJobId,
                      };

                      console.log(stopData);

                      const routeData = {
                        frequency: "once",
                        name: stopName,
                        address: selectedServiceLocation.address,
                        stopObject: stopData,
                        routeId: stopData.routeId, // change
                        jobId: newJobId,
                        serviceLocationId:
                          selectedServiceLocation.serviceLocationId,
                        type: "stop",
                        completed: false,
                        stopId: stopData.stopId,
                        contractor: selectedEmployee,
                        location: json.results[0].geometry.location,
                        customerData: {
                          name:
                            selectedCustomer.customerFirstName +
                            " " +
                            selectedCustomer.customerLastName,
                          customerId: selectedCustomer.customerId,
                          businessId: selectedBusiness,
                        },
                      };

                      db.collection("Businesses")
                        .doc(selectedBusiness)
                        .collection("Workers")
                        .doc(selectedEmployee.uid)
                        .collection("Route")
                        .doc("JobStops")
                        .set(
                          {
                            route:
                              firebase.firestore.FieldValue.arrayUnion(
                                routeData
                              ),
                            checkDistanceTime: true,
                          },
                          { merge: true }
                        );

                      await db
                        .collection("Businesses")
                        .doc(selectedBusiness)
                        .collection("Workers")
                        .doc(selectedEmployee.uid)
                        .update({ lastUpdated: dayjs().unix() });

                      db.collection("Businesses")
                        .doc(selectedBusiness)
                        .collection("Jobs")
                        .doc(newJobId)
                        .update({
                          stops:
                            firebase.firestore.FieldValue.arrayUnion(stopData),
                          status: "scheduled",
                        })
                        .then(() => {
                          db.collection("Businesses")
                            .doc(props.selectedBusiness)
                            .get()
                            .then((documentSnapshot) => {
                              const businessData = documentSnapshot.data();

                              firebase
                                .auth()
                                .currentUser.getIdToken()
                                .then(async (token) => {
                                  fetch(
                                    "https://us-central1-symbri-production.cloudfunctions.net/sendCustomerNotification",
                                    {
                                      method: "POST",
                                      body: JSON.stringify({
                                        token: token,
                                        notificationData: {
                                          hasApp: {
                                            body:
                                              "A new stop named " +
                                              stopName +
                                              " for the job " +
                                              jobTitle +
                                              " has been created!.",
                                            title: "Job stop created!",
                                          },
                                          noApp: {
                                            body:
                                              "A new stop named " +
                                              stopName +
                                              " for the job " +
                                              jobTitle +
                                              " has been created!. Please download the app and login into view the the stop details and message " +
                                              businessData.name +
                                              " with any questions.",
                                            title: "Job stop created!",
                                          },
                                        },
                                        customerId: selectedCustomer.customerId,
                                      }),
                                    }
                                  );
                                  await db
                                    .collection("Businesses")
                                    .doc(props.selectedBusiness)
                                    .collection("Routes")
                                    .doc(routeId)
                                    .update({
                                      reload: !routeQuery.docs[0].data().reload,
                                    });
                                  setSelectedServiceLocation(0);
                                  setJobTitle("");
                                  setJobDescription("");
                                  setStopName("");
                                  setStopDescription("");
                                  setQuickCreate({ name: "None" });
                                  setLoading(false);
                                  handleClose();
                                });
                            });
                        });
                    })
                    .catch((e) => console.log(e));
                });
            } else if (quickCreate.name === "Unscheduled Stop") {
              const stopObject = {
                stopDate: "none",
                stopTitle: stopName,
                stopDescription: stopDescription,
                stopTech: "none",
                scheduledJob: false,
                jobId: newJobId,
                stopId: generateBusinessId(),
                customer: {
                  customerName:
                    selectedCustomer.customerFirstName +
                    " " +
                    selectedCustomer.customerLastName,
                  customerId: selectedCustomer.customerId,
                },
                stopServiceLocation: selectedServiceLocation,
                stopServiceLocationCoordinates: await Geocoder.from(
                  selectedServiceLocation.address
                ).then((json) => {
                  return json.results[0].geometry.location;
                }),
              };

              // replace this shit with new notification

              db.collection("Businesses")
                .doc(props.selectedBusiness)
                .collection("Jobs")
                .doc(newJobId)
                .update({
                  unscheduledStops:
                    firebase.firestore.FieldValue.arrayUnion(stopObject),
                })
                .then(() => {
                  db.collection("Businesses")
                    .doc(props.selectedBusiness)
                    .get()
                    .then((documentSnapshot) => {
                      const businessData = documentSnapshot.data();

                      firebase
                        .auth()
                        .currentUser.getIdToken()
                        .then(async (token) => {
                          fetch(
                            "https://us-central1-symbri-production.cloudfunctions.net/sendCustomerNotification",
                            {
                              method: "POST",
                              body: JSON.stringify({
                                token: token,
                                notificationData: {
                                  hasApp: {
                                    body:
                                      "A new stop named " +
                                      stopName +
                                      " for the job " +
                                      jobTitle +
                                      " has been created but is unscheduled.",
                                    title: "Unscheduled job stop created!",
                                  },
                                  noApp: {
                                    body:
                                      "A new stop named " +
                                      stopName +
                                      " for the job " +
                                      jobTitle +
                                      " has been created but is unscheduled. Please download the app and login into view the the stop details and message " +
                                      businessData.name +
                                      " with any questions.",
                                    title: "Unscheduled job stop created!",
                                  },
                                },
                                customerId: selectedCustomer.customerId,
                              }),
                            }
                          );
                          db.collection("Businesses")
                            .doc(props.selectedBusiness)
                            .collection("Routes")
                            .where("active", "==", true)
                            .get()
                            .then(async (routeQuery) => {
                              await db
                                .collection("Businesses")
                                .doc(props.selectedBusiness)
                                .collection("Routes")
                                .doc(routeQuery.docs[0].id)
                                .update({
                                  reload: !routeQuery.docs[0].data().reload,
                                });
                              setJobTitle("");
                              setJobDescription("");
                              setSelectedServiceLocation(0);
                              setLoading(false);
                              handleClose();
                            });
                        });
                    });
                });
            } else {
              firebase
                .auth()
                .currentUser.getIdToken()
                .then((token) => {
                  fetch(
                    "https://us-central1-symbri-production.cloudfunctions.net/sendCustomerNotification",
                    {
                      method: "POST",
                      body: JSON.stringify({
                        token: token,
                        notificationData: {
                          hasApp: {
                            body: "New job created!",
                            title: "New job " + jobTitle + " created!",
                          },
                          noApp: {
                            body: "New job created!",
                            title: "New job " + jobTitle + " created!",
                          },
                        },
                        customerId: selectedCustomer.customerId,
                      }),
                    }
                  );
                  setJobTitle("");
                  setJobDescription("");
                  setSelectedServiceLocation(0);
                  setLoading(false);
                  handleClose();
                });
            }
          });
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => console.log()}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            {" "}
                            New Job{" "}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => handleClose()}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Get started by filling in the information below to
                            add a job to this customer.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Job Title{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  onChange={(change) =>
                                    setJobTitle(change.target.value)
                                  }
                                  type="text"
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                />
                              </div>
                            </div>
                            <div>
                              <div className="flex items-center">
                                <label
                                  htmlFor="description"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Job Description{" "}
                                </label>

                                <div className="ml-auto">
                                  <CheckBox
                                    onChange={() =>
                                      setShowDescriptionInput(
                                        !showDescriptionInput
                                      )
                                    }
                                    checked={showDescriptionInput}
                                  />
                                </div>
                              </div>

                              <div className="mt-1">
                                {showDescriptionInput && (
                                  <input
                                    onChange={(change) =>
                                      setJobDescription(change.target.value)
                                    }
                                    type="text"
                                    className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                  />
                                )}
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Customer{" "}
                              </label>
                              <div className="mt-1">
                                <SelectCustomerSearch
                                  businessId={props.businessId}
                                  setSelectedCustomer={setSelectedCustomer}
                                  customers={customers}
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Service Location{" "}
                              </label>
                              <div className="mt-1">
                                <SelectServiceLocation
                                  businessId={props.businessId}
                                  setSelectedServiceLocation={
                                    setSelectedServiceLocation
                                  }
                                  serviceLocations={serviceLocations}
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Category{" "}
                              </label>
                              <div className="mt-1">
                                <SelectCategory
                                  allCategories={allCategories}
                                  setSelectedCategory={setSelectedCategory}
                                />
                              </div>
                            </div>

                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Quick Create{" "}
                              </label>
                              <div className="mt-1">
                                <SelectQuickCreate
                                  setQuickCreate={setQuickCreate}
                                  businessId={props.businessId}
                                />
                              </div>
                            </div>

                            {quickCreate.name !== "None" && (
                              <div>
                                <label
                                  htmlFor="description"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Stop Name{" "}
                                </label>
                                <div className="mt-1">
                                  <input
                                    onChange={(change) =>
                                      setStopName(change.target.value)
                                    }
                                    type="text"
                                    className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                  />
                                </div>
                              </div>
                            )}
                            {quickCreate.name !== "None" && (
                              <div>
                                <div className="flex items-center">
                                  <label
                                    htmlFor="description"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    {" "}
                                    Stop Description{" "}
                                  </label>

                                  <div className="ml-auto">
                                    <CheckBox
                                      onChange={() =>
                                        setShowStopDescriptionInput(
                                          !showStopDescriptionInput
                                        )
                                      }
                                      checked={showStopDescriptionInput}
                                    />
                                  </div>
                                </div>
                                {showStopDescriptionInput && (
                                  <div className="mt-1">
                                    <input
                                      onChange={(change) =>
                                        setStopDescription(change.target.value)
                                      }
                                      type="text"
                                      className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                            {quickCreate.name === "Scheduled Stop" && (
                              <div>
                                <label
                                  htmlFor="description"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Stop Date{" "}
                                </label>
                                <div className="mt-1">
                                  <input
                                    onChange={(change) =>
                                      setStopDate(change.target.value)
                                    }
                                    type="date"
                                    className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                  />
                                </div>
                              </div>
                            )}
                            {quickCreate.name === "Scheduled Stop" && (
                              <div>
                                <label
                                  htmlFor="description"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Employee{" "}
                                </label>
                                <div className="mt-1">
                                  <SelectEmployeeSearch
                                    businessId={props.businessId}
                                    setSelectedEmployee={setSelectedEmployee}
                                    employees={employees}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => handleClose(false)}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleAddJob}
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        {loading ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div>Loading </div>
                            <Rings
                              height={22}
                              width={22}
                              color="white"
                              arialLabel="loading-indicator"
                            />
                          </div>
                        ) : (
                          "Add"
                        )}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
