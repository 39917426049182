import React from "react";
import { useEffect, useState } from "react";
import Geocoder from "react-native-geocoding";
import GoogleMapReact from "google-map-react";
import firebase from "firebase/compat/app";
import { FiXCircle } from "react-icons/fi";
import _, { set } from "lodash";

const Map = (props) => {
  const db = firebase.firestore();
  const [mapHeight, setMapHeight] = useState("calc(100vh - 66px)");
  const [zipcodes, setZipcodes] = useState([]);
  const [activeBoundries, setActiveBoundries] = useState([]);
  const [mapState, setMapState] = useState();

  Geocoder.init("AIzaSyAdCqyznNDDWC84JmiS6DouRrGgHnlSzAg");

  const getZipcodes = async () => {
    const storageKey = "zipcodesData";
    let data = localStorage.getItem(storageKey);

    if (data) {
      data = JSON.parse(data);
    } else {
      const res = await fetch(
        "https://us-central1-symbri-production.cloudfunctions.net/getZipcodes?zipcode=32907"
      );
      data = await res.json();

      localStorage.setItem(storageKey, JSON.stringify(data));
    }

    setActiveBoundries(data);
  };

  const getBusinessServiceZipcodes = async () => {
    const business = await db
      .collection("Businesses")
      .doc(props.businessId)
      .get();

    if (business.exists) {
      const data = business.data();
      if (data.serviceZipcodes) {
        setZipcodes(data.serviceZipcodes);
      }
    }
  };

  useEffect(() => {
    getBusinessServiceZipcodes();
    getZipcodes();
  }, []);

  useEffect(() => {
    // Create a debounced version of updateBoundariesOnMap
    const debouncedUpdate = _.debounce(() => {
      if (mapState && activeBoundries.length > 0) {
        updateBoundariesOnMap(activeBoundries, props.searchTerm);
        console.log("running boundaries code");
      }
    }, 500); // 500ms delay

    // Call the debounced function
    debouncedUpdate();

    // Cleanup the debounce function on component unmount or when effect dependencies change
    return () => {
      debouncedUpdate.cancel();
    };
  }, [mapState, activeBoundries, props.searchTerm, zipcodes]); // Listen to searchText along with other dependencies

  const updateBoundariesOnMap = (boundaries, zipcodeSearch) => {
    const featureLayer = mapState.getFeatureLayer("POSTAL_CODE");

    // Convert the array to an object for O(1) lookup times
    let boundariesObject = boundaries
      .filter((a) => a.zipCode.includes(zipcodeSearch))
      .reduce((obj, item) => {
        obj[item.placeId] = item;
        return obj;
      }, {});

    featureLayer.style = (options) => {
      const existsData = boundariesObject[options.feature.placeId];

      if (existsData) {
        const featureStyleOptions = {
          strokeColor: "black",
          strokeOpacity: 1.0,
          strokeWeight: 3.0,
          fillColor: zipcodes.some((a) => a.zipCode === existsData.zipCode)
            ? "#4F46E5"
            : "white",
          fillOpacity: 0.5,
        };
        return featureStyleOptions;
      }
    };

    featureLayer.addListener("click", async (event) => {
      if (event.features && event.features.length > 0) {
        const clickedFeature = event.features[0];

        const zip = activeBoundries.find(
          (boundary) => boundary.placeId === clickedFeature.placeId
        );

        if (zip) {
          if (zipcodes.some((a) => a.zipCode === zip.zipCode)) {
            setZipcodes(zipcodes.filter((z) => z.zipCode !== zip.zipCode));
            await db
              .collection("Businesses")
              .doc(props.businessId)
              .set(
                {
                  serviceZipcodes:
                    firebase.firestore.FieldValue.arrayRemove(zip),
                },
                { merge: true }
              );
            console.log("RUNNUNG ZIPCODES");
            await db
              .collection("Profiles")
              .doc(props.businessId)
              .set(
                {
                  serviceZipcodes: firebase.firestore.FieldValue.arrayRemove(
                    zip.zipCode
                  ),
                },
                { merge: true }
              );
          } else {
            setZipcodes([...zipcodes, zip]);
            await db
              .collection("Businesses")
              .doc(props.businessId)
              .set(
                {
                  serviceZipcodes:
                    firebase.firestore.FieldValue.arrayUnion(zip),
                },
                { merge: true }
              );
            console.log("RUNNUNG ZIPCODES");
            await db
              .collection("Profiles")
              .doc(props.businessId)
              .set(
                {
                  serviceZipcodes: firebase.firestore.FieldValue.arrayUnion(
                    zip.zipCode
                  ),
                },
                { merge: true }
              );
          }
        }
      } else {
        console.error("No features found on this click event");
      }
    });
  };

  const handleApiLoaded = async (map, __maps) => {
    setMapState(map);

    console.log("Map loaded");
  };

  return (
    <div
      id="map"
      className="w-full overflow-hidden shadow-lg flex justify-center items-center"
      style={{ height: mapHeight }}
    >
      <GoogleMapReact
        apiKey="AIzaSyAdCqyznNDDWC84JmiS6DouRrGgHnlSzAg"
        defaultCenter={{
          lat: 29.424349,
          lng: -98.491142,
        }}
        defaultZoom={8}
        apType="satellite"
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        options={{
          fullscreenControl: false,
          zoomControl: true,
          clickableIcons: false,
          disableDefaultUI: true,
          mapId: "13f11b75006ee280",
        }}
      ></GoogleMapReact>
    </div>
  );
};

export default Map;
