import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import NewChemical from "./NewChemical";
import EditChemical from "./Edit";
import { BiTrash } from "react-icons/bi";

export default function Chemicals(props) {
  const selectedBusiness = props.businessId;
  const db = firebase.firestore();
  const [chemicals, setChemicals] = useState([]);
  const [createChemicalDisplay, setCreateChemicalDisplay] = useState(false);
  const [selectedChemical, setSelectedChemical] = useState();
  const [editChemicalDisplay, setEditChemicalDisplay] = useState(false);
  const searchTerm = props.searchTerm;

  useEffect(() => {
    console.log(selectedBusiness);
    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Chemicals")
      .onSnapshot((snapshot) => {
        let chemicalsArray = [];
        for (let index = 0; index < snapshot.docs.length; index++) {
          const chemicalDoc = snapshot.docs[index].data();
          chemicalsArray.push(chemicalDoc);
        }
        setChemicals(chemicalsArray);
      });
  }, []);

  const handleEditClick = (value) => {
    setSelectedChemical(value);
    setEditChemicalDisplay(true);
  };

  const handleDeleteClick = (chemical) => {
    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Chemicals")
      .doc(chemical.chemicalId)
      .delete();
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Chemicals</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            onClick={() => setCreateChemicalDisplay(true)}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add Chemical
          </button>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Dose
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Price
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {chemicals
                    .filter((a) =>
                      searchTerm !== ""
                        ? a.chemicalName
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        : true
                    )
                    .map((chemical) => (
                      <tr key={chemical.chemicalId}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {chemical.chemicalName.replace(
                            /(^\w{1})|(\s+\w{1})/g,
                            (letter) => letter.toUpperCase()
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {chemical.chemicalDose.replace(
                            /(^\w{1})|(\s+\w{1})/g,
                            (letter) => letter.toUpperCase()
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {chemical.chemicalPricePerDose.replace(
                            /(^\w{1})|(\s+\w{1})/g,
                            (letter) => letter.toUpperCase()
                          )}
                        </td>
                        <td
                          style={{ marginLeft: "50%" }}
                          className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex items-center"
                        >
                          <div
                            onClick={() => handleEditClick(chemical)}
                            className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                          >
                            Edit
                          </div>
                          <div
                            onClick={() => handleDeleteClick(chemical)}
                            className="text-indigo-600 hover:text-indigo-900 cursor-pointer ml-8"
                          >
                            Delete
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <NewChemical
                selectedBusiness={selectedBusiness}
                setOpen={setCreateChemicalDisplay}
                open={createChemicalDisplay}
                userPermissions={props.userPermissions}
              />
              <EditChemical
                selectedChemical={selectedChemical}
                selectedBusiness={selectedBusiness}
                setOpen={setEditChemicalDisplay}
                open={editChemicalDisplay}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
