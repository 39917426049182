import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import dayjs from "dayjs";

export default function ServiceLocationMetrics(props) {
  const [costs, setCosts] = useState([]);
  const [averageMonthlyCost, setAverageMonthlyCost] = useState(0);
  const db = firebase.firestore();

  const calculateMetrics = async () => {
    const reportsRef = await db
      .collection("Customers")
      .doc(props.serviceLocation.customerId)
      .collection("PoolReports")
      .where("businessId", "==", props.serviceLocation.businessId)
      .get();

    const reports = reportsRef.docs.map((doc) => doc.data());

    const serviceLocationReports = reports.filter(
      (report) =>
        report.reportServiceLocationId ===
        props.serviceLocation.serviceLocationId
    );

    // Get last 6 months using dayjs
    const last6Months = Array.from({ length: 6 }, (_, i) => {
      return dayjs().subtract(i, "month").format("MMMM");
    }).reverse();

    const monthsData = await Promise.all(
      last6Months.map(async (month) => {
        // Filter reports for the current month
        const monthReports = serviceLocationReports.filter(
          (report) =>
            dayjs(report.reportDate.seconds * 1000).format("MMMM") === month
        );

        // Extract chemicals from the reports
        const chemicals = monthReports
          .map((report) =>
            report.reportObjects.find((a) => a.type === "chemicalsAdded")
          )
          .filter(Boolean); // Filter out undefined values

        const chemicalObjects = chemicals.flat();

        // Fetch chemical data for all chemicals in parallel
        const chemicalCosts = await Promise.all(
          chemicalObjects.map(async (chemical) => {
            const chemsPromises = chemical.value.map(async (chem) => {
              const chemicalId = chem.chemicalData.chemicalId;

              const chemicalRef = await db
                .collection("Businesses")
                .doc(props.serviceLocation.businessId)
                .collection("Chemicals")
                .doc(chemicalId)
                .get();

              const chemicalData = chemicalRef.data();

              return {
                chemicalName: chemicalData.chemicalName,
                value:
                  parseInt(chemicalData.chemicalPricePerDose.replace("$", "")) *
                  chem.value,
              };
            });

            // Resolve all chemical promises for this report
            return Promise.all(chemsPromises);
          })
        );

        // Flatten the result since chemicalCosts is an array of arrays
        const flatChemicalCosts = chemicalCosts.flat();

        // Aggregate chemicals by name and sum values for each month
        const chemicalsArray = flatChemicalCosts.reduce((acc, chemical) => {
          const existingChemical = acc.find(
            (c) => c.chemicalName === chemical.chemicalName
          );
          if (existingChemical) {
            existingChemical.value += chemical.value;
          } else {
            acc.push({
              chemicalName: chemical.chemicalName,
              value: chemical.value,
            });
          }
          return acc;
        }, []);

        // Return data for this month with all chemicals grouped in one array
        return {
          name: month,
          chemicals: chemicalsArray,
        };
      })
    );

    // Calculate the average monthly cost

    const totalCosts = monthsData.reduce((acc, month) => {
      const monthTotal = month.chemicals.reduce(
        (acc, chemical) => acc + chemical.value,
        0
      );
      return acc + monthTotal;
    }, 0);

    console.log(monthsData);

    setAverageMonthlyCost(totalCosts);

    setCosts(monthsData);
  };

  useEffect(() => {
    calculateMetrics();
  }, []);

  // Format data for the chart
  const formattedData = costs.map((month) => {
    const result = { name: month.name };
    month.chemicals.forEach((chemical) => {
      result[chemical.chemicalName] = chemical.value;
    });
    return result;
  });

  // Dynamically get chemical names from the first entry with chemicals (if any)
  const chemicalNames =
    costs
      .find((month) => month.chemicals.length > 0)
      ?.chemicals.map((chemical) => chemical.chemicalName) || [];

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <div className="px-4 sm:px-6 lg:px-0">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg border-gray-200 border-2 h-[400px]">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Chemical Cost
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-right text-sm font-semibold text-gray-900 sm:pl-6 flex-shrink cursor-pointer"
                    >
                      Average Monthly Cost: ${averageMonthlyCost.toFixed(2)}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white pt-8">
                  <tr>
                    <td className="px-4 py-4">
                      {/* Recharts Bar chart rendered here */}
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={formattedData}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis tickFormatter={(value) => `$${value}`} />
                          <Tooltip formatter={(value) => `$${value}`} />
                          <Legend />
                          {/* Render a Bar for each chemical */}
                          {chemicalNames.map((chemicalName, index) => (
                            <Bar
                              key={chemicalName}
                              dataKey={chemicalName}
                              fill={getRandomColor()} // Generate a random color
                            />
                          ))}
                        </BarChart>
                      </ResponsiveContainer>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
