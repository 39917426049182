import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import CompanyChemicalMetrics from "./CompanyChemicalMetrics";
import CompanyCustomerGrowth from "./CompanyCustomerGrowth";
import CompanyIncomeGrowth from "./CompanyIncomeGrowth";
import dayjs from "dayjs";

const Metrics = ({ businessId }) => {
  const db = firebase.firestore();
  const [stats, setStats] = useState([
    { name: "Total Customers", value: "3" },
    { name: "New Customers/30d", value: "3" },
    { name: "Total Employees", value: "2" },
    { name: "New Leads", value: "1" },
    { name: "Total Leads", value: "1" },
    { name: "Lead Conversions", value: "100%" },
  ]);

  const getStats = async () => {
    const currentMonth = dayjs().format("MMMM-YYYY");
    const previousMonth = dayjs().subtract(1, "month").format("MMMM-YYYY");
    const businessMetricsDoc = await db
      .collection("Businesses")
      .doc(businessId)
      .collection("Metrics")
      .doc(currentMonth)
      .get();

    let currentMonthCount = 0;

    if (businessMetricsDoc.exists) {
      const data = businessMetricsDoc.data();
      currentMonthCount = data.customerCount;
    }

    const previousMonthDoc = await db
      .collection("Businesses")
      .doc(businessId)
      .collection("Metrics")
      .doc(previousMonth)
      .get();

    let previousMonthCount = 0;

    if (previousMonthDoc.exists) {
      const data = previousMonthDoc.data();
      previousMonthCount = data.customerCount;
    }

    // get the total number of employees

    const employeesDoc = await db
      .collection("Businesses")
      .doc(businessId)
      .collection("Workers")
      .get();

    const employeesCount = employeesDoc.docs.length;

    setStats([
      { name: "Total Customers", value: currentMonthCount },
      {
        name: "New Customers/30d",
        value: currentMonthCount - previousMonthCount,
      },
      { name: "Total Employees", value: employeesCount },
      { name: "New Leads/30d", value: "1" },
      { name: "Total Leads", value: "1" },
      { name: "Lead Conversions", value: "100%" },
    ]);
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <div className="p-6">
      <div className="bg-gray-900">
        <div className="mx-auto">
          <div className="grid grid-cols-1 gap-px bg-white/5 sm:grid-cols-2 lg:grid-cols-6">
            {stats.map((stat) => (
              <div
                key={stat.name}
                className="bg-gray-900 px-4 py-6 sm:px-6 lg:px-8"
              >
                <p className="text-sm font-medium leading-6 text-gray-400">
                  {stat.name}
                </p>
                <p className="mt-2 flex items-baseline gap-x-2">
                  <span className="text-4xl font-semibold tracking-tight text-white">
                    {stat.value}
                  </span>
                  {stat.unit ? (
                    <span className="text-sm text-gray-400">{stat.unit}</span>
                  ) : null}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div>
        <CompanyChemicalMetrics businessId={businessId} />
        <CompanyCustomerGrowth businessId={businessId} />
        <CompanyIncomeGrowth businessId={businessId} />
      </div>
    </div>
  );
};

export default Metrics;
