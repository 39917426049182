import React, { useState } from "react";
import symbriLogo from "../Assets/whiteLogo.png";
import SignUp from "./SignUp";
import firebase from "firebase/compat/app";
import { toast } from "react-toastify";
import ForgotPassword from "./ForgotPassword";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState();
  const [signUpDisplay, setSignUpDisplay] = useState(false);
  const [forgotPasswordDisplay, setForgotPasswordDisplay] = useState(false);
  const db = firebase.firestore();

  const handleLogin = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email.trim(), password.trim())
      .then((userCredential) => {
        userCredential.user
          .getIdTokenResult()
          .then((idTokenResult) => {
            db.collection("Users")
              .doc(userCredential.user.uid)
              .get()
              .then((documentSnapshot) => {
                const userData = documentSnapshot.data();
                if (!userData) {
                  toast.warn("Invalid email or password.");
                }
                if (
                  userData.userType === "worker" ||
                  userData.userType === "owner" ||
                  userData.userType === "business"
                ) {
                  if (
                    !userData.permissions.includes("Use of the website CRM")
                  ) {
                    toast.warn("You cannot login in here with that account.");
                    return;
                  }

                  props.setUserAuth(true);
                  console.log(userData.businessId);
                  props.setBusinessId(userData.businessId);
                  db.collection("Businesses")
                    .doc(userData.businessId)
                    .get()
                    .then((documentSnapshot) => {
                      const businessDaata = documentSnapshot.data();
                      props.setBusinessName(businessDaata.name);
                    });

                  console.log(userData.businessId);
                } else {
                  toast.warn("You cannot login in here with that account.");
                }
              });
          })
          .catch((error) => {
            const errorMessage = error.message;
            toast.warn(errorMessage);
          });
      })
      .catch((error) => {
        const errorMessage = error.message;
        toast.warn(errorMessage);
      });
  };

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full">
        ```
      */}
      <div
        id="loginBody"
        className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8"
      >
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-24 w-auto"
            src={symbriLogo}
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl tracking-tight font-bold text-white">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md mb-60">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="space-y-6" action="#" method="POST">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    onChange={(change) => setEmail(change.target.value)}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    onChange={(change) => setPassword(change.target.value)}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="text-sm">
                  <div
                    onClick={() => setForgotPasswordDisplay(true)}
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot your password?
                  </div>
                </div>
              </div>
              <div>
                <button
                  onClick={handleLogin}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Sign In
                </button>
                <button
                  onClick={() => setSignUpDisplay(true)}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4"
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
        <SignUp
          setUserAuth={props.setUserAuth}
          setOpen={setSignUpDisplay}
          open={signUpDisplay}
          setBusinessName={props.setBusinessName}
          setBusinessId={props.setBusinessId}
        />
        <ForgotPassword
          setOpen={setForgotPasswordDisplay}
          open={forgotPasswordDisplay}
        />
      </div>
    </>
  );
};

export default Login;
