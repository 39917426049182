import React, { useRef, useState } from "react";
import { Switch } from "@headlessui/react";
import { getMediaStream } from "../Tools/getMediaStream";
import firebase from "firebase/compat/app";
import { useEffect } from "react";
import AutoComplete from "react-google-autocomplete";
import { FaPencilAlt, FaTimes } from "react-icons/fa";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const OnlineProfile = (props) => {
  const [enabled, setEnabled] = useState(false);
  const [images, setImages] = useState([]);
  const [features, setFeatures] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [edit, setEdit] = useState(false);
  const [displayNewService, setDisplayNewService] = useState(false);
  const fileInputRef = useRef(null);
  const storage = firebase.storage();
  const storageRef = storage.ref();
  const db = firebase.firestore();
  const [selectedService, setSelectedService] = useState(null);
  const [businessLogo, setBusinessLogo] = useState(null);
  const [ownerName, setOwnerName] = useState("");
  const [mainImage, setMainImage] = useState(null);
  const [profile, setProfile] = useState(null);
  const [introduction, setIntroduction] = useState("");
  const [featuresProjects, setFeaturesProjects] = useState([]);
  const [newServiceName, setNewServiceName] = useState("");
  const [businessAbout, setBusinessAbout] = useState("");
  const [companyAddress, setCompanyAddress] = useState(
    "9105 Ellis Rd, Melbourne, FL 32905"
  );
  const [hours, setHours] = useState({
    Mon: { start: "08:00", end: "18:00" },
    Tue: { start: "08:00", end: "18:00" },
    Wed: { start: "08:00", end: "18:00" },
    Thu: { start: "08:00", end: "18:00" },
    Fri: { start: "08:00", end: "18:00" },
    Sat: { start: "09:00", end: "18:00" },
    Sun: { start: "09:00", end: "18:00" },
  });

  const paymentOptions = [
    "Apple Pay",
    "Cash",
    "Check",
    "Credit card",
    "PayPal",
    "Venmo",
    "Zelle",
  ];

  const [acceptedPayments, setAcceptedPayments] = useState([]);

  const handleCheckboxChange = (option) => {
    setAcceptedPayments((prevAcceptedPayments) => {
      if (prevAcceptedPayments.includes(option)) {
        // Remove the option if it's already in the array
        return prevAcceptedPayments.filter((payment) => payment !== option);
      } else {
        // Add the option if it's not in the array
        return [...prevAcceptedPayments, option];
      }
    });
  };

  const getProfileInformation = async () => {
    const profileRef = db.collection("Profiles").doc(props.businessId);

    const doc = await profileRef.get();

    if (doc.exists) {
      const data = doc.data();
      setTitle(data.title);
      setDescription(data.description);
      setPrice(data.price);
      setImages(data.images);
      setUploadedImages(data.images);
      setFeatures(data.features);
      setEnabled(data.profileEnabled);
      setMainImage(data.images[0]);
      setProfile(data);
      setIntroduction(data.introduction);
      setAcceptedPayments(data.acceptedPayments);
      setFeaturesProjects(data.images);
      setBusinessAbout(data.businessAbout);
      setCompanyAddress(data.companyAddress);
      setHours(data.hours || hours);
    }
  };

  const getBusinessLogo = async () => {
    const businessDoc = await db
      .collection("Businesses")
      .doc(props.businessId)
      .get();
    const businessData = businessDoc.data();
    setBusinessLogo(businessData.logo);
    setOwnerName(businessData.ownerName);
  };

  useEffect(() => {
    getProfileInformation();
    getBusinessLogo();
  }, []);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  // Function to handle file input change
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("File chosen:", file.name);
      // display image in the UI using URL
      const imageUrl = URL.createObjectURL(file);

      // Set image state

      setImages((prevImages) => [...prevImages, imageUrl]);

      // generate random 6 digit string

      const randomString = Math.random().toString(36).substring(2, 8);

      const imageRef = storageRef.child(
        props.businessId + "/profile/" + randomString
      );
      await imageRef.put(file);

      const url = await getMediaStream(
        props.businessId + "/profile/" + randomString
      );

      console.log(url);

      setFeaturesProjects((prevImages) => [...prevImages, url]);
      setMainImage(url);
    }
  };

  const saveProfileInformation = async () => {
    const profileRef = db.collection("Profiles").doc(props.businessId);

    const profile = {
      title: title,
      description: description,
      price: price,
      images: featuresProjects,
      features: features,
      businessId: props.businessId,
      profileEnabled: enabled,
      mainImage: mainImage,
      introduction: introduction,
      acceptedPayments: acceptedPayments,
      businessAbout: businessAbout,
      companyAddress: companyAddress,
      hours: hours,
    };

    await profileRef.set(profile, { merge: true });

    setEdit(false);
  };

  const handleRemoveFeature = (index) => {
    setFeaturesProjects((prevImages) =>
      prevImages.filter((image, i) => i !== index)
    );
  };

  const removeService = (index) => {
    setFeatures((prevFeatures) =>
      prevFeatures.filter((feature, i) => i !== index)
    );
  };

  const addService = () => {
    setFeatures((prevFeatures) => [
      ...prevFeatures,
      { name: newServiceName, price: "" },
    ]);
    setNewServiceName("");
  };

  const handleChangeHours = (day, type, value) => {
    setHours({
      ...hours,
      [day]: {
        ...hours[day],
        [type]: value,
      },
    });
  };

  function convertToAmPm(timeString) {
    const [hour, minute] = timeString.split(":");
    const hours = parseInt(hour, 10);
    const ampm = hours >= 12 ? "PM" : "AM";
    const convertedHour = hours % 12 || 12; // Converts 0 to 12 for 12 AM
    return `${convertedHour}:${minute} ${ampm}`;
  }

  const handleUpdateListMyProfile = () => {
    const profileRef = db.collection("Profiles").doc(props.businessId);

    profileRef.update({
      profileEnabled: !enabled,
    });

    setEnabled(!enabled);
  };

  return profile ? (
    <div>
      <div className="flex bg-[#202937] h-16 items-center">
        <FaPencilAlt className="text-white ml-4" />
        <h1
          onClick={() => (edit ? saveProfileInformation() : setEdit(true))}
          className="text-xl font-bold text-white ml-2 cursor-pointer"
        >
          {edit ? "Save Online Profile" : "Edit Online Profile"}
        </h1>
        <div className="ml-auto text-white">List My Profile</div>
        <Switch
          checked={enabled}
          onChange={handleUpdateListMyProfile}
          className={classNames(
            enabled ? "bg-indigo-600" : "bg-gray-200",
            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ml-2 mr-4"
          )}
        >
          <span
            className={classNames(
              enabled ? "translate-x-5" : "translate-x-0",
              "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
            )}
          >
            <span
              className={classNames(
                enabled
                  ? "opacity-0 duration-100 ease-out"
                  : "opacity-100 duration-200 ease-in",
                "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
              )}
              aria-hidden="true"
            >
              <svg
                className="h-3 w-3 text-gray-400"
                fill="none"
                viewBox="0 0 12 12"
              >
                <path
                  d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span
              className={classNames(
                enabled
                  ? "opacity-100 duration-200 ease-in"
                  : "opacity-0 duration-100 ease-out",
                "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
              )}
              aria-hidden="true"
            >
              <svg
                className="h-3 w-3 text-indigo-600"
                fill="currentColor"
                viewBox="0 0 12 12"
              >
                <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
              </svg>
            </span>
          </span>
        </Switch>
      </div>

      <div className="flex flex-col items-start justify-center bg-white w-full p-6 md:p-12">
        <div className="flex md:hidden w-full pb-6 pl-2">
          <img
            src={businessLogo || profile.mainImage}
            alt={profile.title}
            className="w-16 h-16 rounded-full object-cover"
          />
        </div>
        <div className="flex items-start space-x-4 w-full md:w-2/3">
          <img
            src={businessLogo || profile.mainImage}
            alt={profile.title}
            className="w-16 h-16 rounded-full object-cover hidden md:block"
          />
          <div className="w-full">
            <h2 className="text-xl font-bold text-black">{profile.title}</h2>
            <div className="hidden md:flex space-x-4 mt-2">
              <div className="flex items-center text-sm mt-2 md:mt-0">
                <span className="text-black">Symbri Guaranteed</span>
              </div>

              <div className="flex items-center text-sm mt-2 md:mt-0">
                <span className="text-black">Available By Appointment</span>
              </div>

              <div className="flex items-center text-sm mt-2 md:mt-0">
                <span className="text-black">Eco-Friendly</span>
              </div>
            </div>
            <div className="w-full">
              <div className="mt-6">
                <p className="font-bold text-black inline-block">
                  Introduction:{" "}
                </p>
                {!edit && (
                  <p className="text-black inline ml-2">{introduction}</p>
                )}

                {edit && (
                  <textarea
                    value={introduction}
                    onChange={(e) => setIntroduction(e.target.value)}
                    className="w-full h-32 border p-2 mt-2 resize-none"
                  />
                )}
              </div>
              <div className="mt-6 flex flex-col md:flex-row w-full">
                <div className="w-full mt-6 md:mt-0">
                  <div className="text-black font-bold mb-4">Payments</div>
                  {!edit ? (
                    <p className="text-black">
                      This pro accepts payments via{" "}
                      {acceptedPayments.join(", ")}.
                    </p>
                  ) : (
                    paymentOptions.map((option) => (
                      <div key={option}>
                        <label className="flex">
                          <input
                            type="checkbox"
                            checked={acceptedPayments.includes(option)}
                            onChange={() => handleCheckboxChange(option)}
                          />
                          <p className="ml-2">{option}</p>
                        </label>
                      </div>
                    ))
                  )}
                </div>
              </div>

              <div className="mt-12 w-full">
                <div>
                  <div className="text-black font-bold text-xl mb-2">
                    Featured Projects
                  </div>
                  <div className="text-gray-600 mb-4">
                    {featuresProjects.length} Photos
                  </div>
                  <div className="flex flex-row justify-start">
                    {featuresProjects.map((feature, index) => (
                      <div
                        key={index}
                        className={index === 0 ? "relative" : "ml-4 relative"}
                      >
                        <img
                          src={feature}
                          alt={`Project ${index + 1}`}
                          className="w-60 h-64 object-cover rounded-lg"
                        />
                        {edit && (
                          <FaTimes
                            className="absolute top-2 right-2 text-white bg-red-500 rounded-full p-1 cursor-pointer"
                            onClick={() => handleRemoveFeature(index)}
                          />
                        )}
                      </div>
                    ))}
                    {edit && (
                      <div className="ml-4">
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />

                        <div
                          onClick={handleImageClick}
                          className="w-60 h-64 object-cover rounded-lg mt-2 mb-2 md:mt-0 md:mb-0 flex justify-center items-center bg-gray-500 cursor-pointer"
                        >
                          <div>ADD PHOTO +</div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-12 border-t border-gray-300 pt-12">
                  <div className="text-black font-bold text-xl mb-2">
                    Services Offered
                  </div>

                  <div className="text-black flex flex-col">
                    {features.map((feature, index) => (
                      <div key={index} className="relative flex items-center">
                        <span>{feature.name}</span>
                        {edit && (
                          <FaTimes
                            className="ml-2 text-black cursor-pointer"
                            onClick={() => removeService(index)}
                          />
                        )}
                      </div>
                    ))}
                  </div>

                  {edit && (
                    <div className="w-1/2">
                      <input
                        value={newServiceName}
                        onChange={(e) => setNewServiceName(e.target.value)}
                        placeholder="Enter service here..."
                        className="border p-2 mt-2 border-gray-300 rounded-md w-1/2"
                      />
                      <button
                        onClick={addService}
                        className="px-4 py-2 border rounded text-white bg-[#202937] ml-4"
                      >
                        Add Service
                      </button>
                    </div>
                  )}
                </div>

                <div className="mt-12 border-t border-gray-300 pt-12">
                  <div className="text-black font-bold text-xl mb-4">
                    About the Business
                  </div>
                  <div className="flex items-start">
                    <img
                      src={businessLogo || profile.mainImage}
                      alt="Business Logo"
                      className="w-12 h-12 rounded-full mr-4 object-cover"
                    />
                    <div className="w-full">
                      <div className="text-black font-bold">{ownerName}</div>
                      <div className="text-gray-600 mb-2">Business Owner</div>
                      {!edit && (
                        <div className="text-black">{businessAbout}</div>
                      )}

                      {edit && (
                        <textarea
                          onChange={(e) => setBusinessAbout(e.target.value)}
                          value={businessAbout}
                          className="w-full h-32 border p-2 mt-2 resize-none"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="hidden mt-12 border-t border-gray-300 pt-6 md:flex flex-col md:flex-row w-full">
                  <div className="w-1/2 pr-4">
                    <div className="text-black font-bold text-xl mb-4">
                      Location
                    </div>
                    <div className="mb-4">
                      <iframe
                        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAdCqyznNDDWC84JmiS6DouRrGgHnlSzAg&q=${companyAddress}`}
                        width="100%"
                        height="300"
                        className="border rounded-lg"
                        allowFullScreen
                        loading="lazy"
                        title="Location Map"
                      ></iframe>
                    </div>
                    <div className="flex items-center w-full">
                      {!edit && (
                        <div className="text-gray-600">{companyAddress}</div>
                      )}

                      {edit && (
                        <AutoComplete
                          options={{
                            types: ["address"],
                          }}
                          className="border p-2 border-gray-300 rounded-md w-full"
                          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_TOKEN}
                          defaultValue={companyAddress}
                          onPlaceSelected={(value) =>
                            setCompanyAddress(value.formatted_address)
                          }
                        />
                      )}
                    </div>
                  </div>

                  <div className="w-1/2 pl-4">
                    <div className="text-black font-bold text-xl mb-4">
                      Hours
                    </div>
                    <div className="text-black">
                      {Object.keys(hours).map((day) => (
                        <div key={day} className="flex justify-between mb-2">
                          <span>{day}</span>
                          {edit ? (
                            <div className="flex">
                              <input
                                value={hours[day].start}
                                type="time"
                                onChange={(e) =>
                                  handleChangeHours(
                                    day,
                                    "start",
                                    e.target.value
                                  )
                                }
                                className="border px-2 mr-2"
                              />
                              <input
                                type="time"
                                value={hours[day].end}
                                onChange={(e) =>
                                  handleChangeHours(day, "end", e.target.value)
                                }
                                className="border px-2"
                              />
                            </div>
                          ) : (
                            <span>
                              {convertToAmPm(hours[day].start)} -{" "}
                              {convertToAmPm(hours[day].end)}
                            </span>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center bg-white w-full p-6 md:p-12"></div>
  );
};

export default OnlineProfile;
