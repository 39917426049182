import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition, Switch } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import firebase from "firebase/compat/app";
import { AiOutlinePlusCircle } from "react-icons/ai";
import SelectServiceLocation from "./SelectServiceLocation";
import { FaTrashAlt } from "react-icons/fa";
import Geocoder from "react-native-geocoding";
import { toast } from "react-toastify";
import SelectByAddress from "./SelectByAddress";
import SelectSubscription from "./SelectSubscription";
import { Rings } from "react-loader-spinner";
import dayjs from "dayjs";
import CheckBox from "../../GlobalComponents/CheckBox";
Geocoder.init(process.env.REACT_APP_GOOGLE_MAPS_API_TOKEN);

export default function AddServiceLocations(props) {
  const db = firebase.firestore();
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const selectedRoute = props.selectedRoute;
  const selectedBusiness = props.selectedBusiness;
  const [allServiceLocations, setAllServiceLocations] = useState([]);
  const [selectedServiceLocation, setSelectedServiceLocation] = useState();
  const [serviceLocationsInRoute, setServiceLocationsInRoute] = useState([]);
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Unscheduled");
  const [unassignedCount, setUnassignedCount] = useState(0);
  const [displayImport, setDisplayImport] = useState(false);
  const [selectedSubscriptions, setSelectionSubscriptions] = useState([]);
  const routeIsAlreadyActive = props.routeIsAlreadyActive;
  const cooldown = useRef();

  useEffect(() => {
    cooldown.current = false;
  }, []);

  const handleActivateRoute = async () => {
    if (routeIsAlreadyActive) {
      console.log(props.routeStartDate);
      const today = dayjs().format("MM/DD/YYYY");
      console.log(today);
      if (!cooldown.current) {
        cooldown.current = true;
        setTimeout(() => {
          cooldown.current = false;
        }, 1000);
        firebase
          .auth()
          .currentUser.getIdToken()
          .then((token) => {
            fetch(
              "https://us-central1-symbri-production.cloudfunctions.net/handleActivateRoute",
              {
                method: "POST",
                body: JSON.stringify({
                  selectedBusiness: selectedBusiness,
                  selectedRoute: selectedRoute,
                  serviceLocationsInRoute: props.serviceLocationsInRoute,
                  routeStartDate: props.routeStartDate,
                  today: today,
                  sendNotifications: true,
                  token: token,
                  routeIsAlreadyActive: routeIsAlreadyActive,
                }),
              }
            );
          });
        setTimeout(() => {
          props.setHasUnactivatedChanges(false);
          handleClose(false);
        }, 1000);
      }
    } else {
      // open other slideout and close this one
      props.handleActivateRouteClick();
      handleClose(false);
    }
  };

  useEffect(() => {
    setOpen(props.open);
    if (props.open) {
      const getData = async () => {
        try {
          const subscriptionsQuery = await db
            .collection("Businesses")
            .doc(selectedBusiness)
            .collection("Subscriptions")
            .get();

          const subs = subscriptionsQuery.docs.map((doc) => doc.data());
          setAllSubscriptions(subs);

          const customersSnapshot = await db
            .collection("Customers")
            .where("businessIds", "array-contains", selectedBusiness)
            .get();

          const customerDataPromises = customersSnapshot.docs.map((doc) =>
            db
              .collection("Customers")
              .doc(doc.data().customerId)
              .collection("ServiceLocations")
              .where("customerId", "==", doc.data().customerId)
              .get()
          );

          const customerDataResults = await Promise.all(customerDataPromises);
          const arrOfServiceLocations = customerDataResults.flatMap((result) =>
            result.docs.map((doc) => doc.data())
          );

          setAllServiceLocations(arrOfServiceLocations);

          const snapshot = await db
            .collection("Businesses")
            .doc(selectedBusiness)
            .collection("Routes")
            .doc(selectedRoute.routeId)
            .collection("ServiceLocations")
            .get();

          const arrOfServiceLocationsInRoute = snapshot.docs.flatMap(
            (location) => {
              const stopCount =
                location.data().recurringStopType.stopIntervalPerWeek;
              return Array(stopCount).fill(location.data());
            }
          );

          setServiceLocationsInRoute(arrOfServiceLocationsInRoute);
          setRefresh((prevRefresh) => !prevRefresh);
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      };

      getData();
    }
  }, [props.open]);

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleReloadData = async () => {
    try {
      // Fetch all customers with the selected business ID
      const customersSnapshot = await db
        .collection("Customers")
        .where("businessIds", "array-contains", selectedBusiness)
        .orderBy("customerFirstName")
        .get();

      let arrOfServiceLocations = [];
      const serviceLocationPromises = [];

      // Collect promises for fetching service locations for each customer
      customersSnapshot.docs.forEach((customerData) => {
        const customer = customerData.data();

        // Add each service location fetch to the promise array
        const serviceLocationPromise = db
          .collection("Customers")
          .doc(customer.customerId)
          .collection("ServiceLocations")
          .where("customerId", "==", customer.customerId)
          .get()
          .then((serviceLocationSnapshot) => {
            serviceLocationSnapshot.docs.forEach((location) => {
              arrOfServiceLocations.push(location.data());
            });
          });

        serviceLocationPromises.push(serviceLocationPromise);
      });

      // Wait for all service location queries to complete
      await Promise.all(serviceLocationPromises);

      // Fetch all service locations in the selected route concurrently
      const routeServiceLocationsSnapshot = await db
        .collection("Businesses")
        .doc(selectedBusiness)
        .collection("Routes")
        .doc(selectedRoute.routeId)
        .collection("ServiceLocations")
        .get();

      const arrOfServiceLocationsInRoute =
        routeServiceLocationsSnapshot.docs.map((doc) => doc.data());

      // Update the state once all data has been fetched
      setServiceLocationsInRoute(arrOfServiceLocationsInRoute);
      setAllServiceLocations(arrOfServiceLocations);

      setRefresh(!refresh);
    } catch (error) {
      console.error("Error reloading data: ", error);
    }
  };

  const handleAddLocationToRoute = () => {
    if (selectedServiceLocation) {
      if (!selectedServiceLocation.subscriptions.subscriptionId) {
        toast.warn(
          "Cannot add a service location that does not have a subscription attached!"
        );
        return;
      }
      setLoading(true);
      db.collection("Businesses")
        .doc(selectedBusiness)
        .collection("Routes")
        .doc(selectedRoute.routeId)
        .collection("ServiceLocations")
        .where("address", "==", selectedServiceLocation.address)
        .where(
          "recurringStopType",
          "==",
          selectedServiceLocation.subscriptions.subscriptionId
        )
        .get()
        .then((documentSnapshot) => {
          if (documentSnapshot.empty) {
            console.log("1111111");
            Geocoder.from(selectedServiceLocation.address).then((json) => {
              const locationCoordinates = json.results[0].geometry.location;
              const sub = selectedServiceLocation.subscriptions;
              console.log("2222222");
              db.collection("Businesses")
                .doc(selectedBusiness)
                .collection("RecurringStopTemplates")
                .doc(sub.connectedStopTemplateId)
                .get()
                .then((documentSnapshot) => {
                  const data = documentSnapshot.data();
                  console.log(
                    selectedServiceLocation.serviceLocationId +
                      sub.subscriptionId
                  );
                  db.collection("Businesses")
                    .doc(selectedBusiness)
                    .collection("Routes")
                    .doc(selectedRoute.routeId)
                    .collection("ServiceLocations")
                    .doc(
                      selectedServiceLocation.serviceLocationId +
                        sub.subscriptionId
                    )
                    .set({
                      assigned: false,
                      recurringStopType: data,
                      coordinates: locationCoordinates,
                      serviceLocationId:
                        selectedServiceLocation.serviceLocationId,
                      customerId: selectedServiceLocation.customerId,
                      routes: [],
                      name: selectedServiceLocation.name,
                      address: selectedServiceLocation.address,
                    })
                    .then(() => {
                      console.log("set");
                      setSelectedServiceLocation();
                      props.handleReloadData();
                      handleReloadData();
                      setLoading(false);
                    });
                });
            });
          } else {
            toast.warn(
              "Service location already added in route or a service location with the same address already exists!"
            );
          }
        });
    } else {
      toast.warn("Must select a service location to add!");
    }
  };

  const handleDeleteServiceLocation = (value) => {
    let indexOfServiceLocationInRoute = props.serviceLocationsInRoute.findIndex(
      (a) => a.serviceLocationId === value.serviceLocationId
    );

    let currentServiceLocations = props.serviceLocationsInRoute;
    currentServiceLocations.splice(indexOfServiceLocationInRoute, 1);
    props.setServiceLocationsInRoute(currentServiceLocations);

    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Routes")
      .doc(selectedRoute.routeId)
      .collection("ServiceLocations")
      .doc(value.serviceLocationId)
      .delete()
      .then(() => {
        props.handleReloadData();
        handleReloadData();
      })
      .catch((e) => console.log(e));
  };

  const handleAddLocationsFromSubscription = () => {
    setLoading(true);
    let allPromises = []; // Initialize an array to store all promises
    for (let index = 0; index < selectedSubscriptions.length; index++) {
      const selectedSubscription = selectedSubscriptions[index];
      let serviceLocationsWithSelectedSubscription = allServiceLocations.filter(
        (a) =>
          a.subscriptions.find(
            (s) => s.subscriptionId === selectedSubscription.subscriptionId
          )
      );
      const promises = serviceLocationsWithSelectedSubscription.map(
        async (serviceLocation) => {
          return db
            .collection("Businesses")
            .doc(selectedBusiness)
            .collection("Routes")
            .doc(selectedRoute.routeId)
            .collection("ServiceLocations")
            .doc(
              serviceLocation.serviceLocationId +
                selectedSubscription.subscriptionId
            )

            .get()
            .then((documentSnapshot) => {
              if (documentSnapshot.empty) {
                return Geocoder.from(serviceLocation.address).then((json) => {
                  const locationCoordinates = json.results[0].geometry.location;
                  const sub = serviceLocation.subscriptions.find(
                    (s) =>
                      s.subscriptionId === selectedSubscription.subscriptionId
                  );

                  return db
                    .collection("Businesses")
                    .doc(selectedBusiness)
                    .collection("RecurringStopTemplates")
                    .doc(sub.connectedStopTemplateId)
                    .get()
                    .then((documentSnapshot) => {
                      const data = documentSnapshot.data();

                      return db
                        .collection("Businesses")
                        .doc(selectedBusiness)
                        .collection("Routes")
                        .doc(selectedRoute.routeId)
                        .collection("ServiceLocations")
                        .doc(serviceLocation.serviceLocationId)
                        .set({
                          assigned: false,
                          recurringStopTypes: [data],
                          coordinates: locationCoordinates,
                          serviceLocationId: serviceLocation.serviceLocationId,
                          customerId: serviceLocation.customerId,
                          routes: [],
                          name: serviceLocation.name,
                          address: serviceLocation.address,
                        });
                    });
                });
              } else {
                toast.warn(
                  "Service location already added in route or a service location with the same address already exists!"
                );
                return null;
              }
            });
        }
      );

      allPromises = allPromises.concat(promises);
    }

    Promise.all(allPromises)
      .then(() => {
        props.handleReloadData();
        setSelectionSubscriptions([]);
        handleReloadData();
        setLoading(false);
        setDisplayImport(false);
      })
      .catch((error) => {
        // Handle error here
      });
  };

  const serviceLocationCounter = {};
  const scheduledStopsCounter = {};

  useEffect(() => {
    if (selectedFilter === "Unscheduled") {
      let count = 0;
      let alreadyCheckedLocations = [];
      serviceLocationsInRoute.forEach((serviceLocation) => {
        // If we've already checked this location, skip it
        if (
          !alreadyCheckedLocations.includes(
            serviceLocation.serviceLocationId +
              serviceLocation.recurringStopType.connectedSubscriptionId
          )
        ) {
          alreadyCheckedLocations.push(
            serviceLocation.serviceLocationId +
              serviceLocation.recurringStopType.connectedSubscriptionId
          );
          const assignedStops = serviceLocation.routes.length;
          const stopIntervalPerWeek =
            serviceLocation.recurringStopType.stopIntervalPerWeek;

          // Determine if there are unscheduled stops
          if (assignedStops < stopIntervalPerWeek) {
            // Add the number of unscheduled stops to the count
            count += stopIntervalPerWeek - assignedStops;
          }
        }
      });

      // Update the unassignedCount ref with the calculated count
      setUnassignedCount(count);
    }
  }, [serviceLocationsInRoute, selectedFilter]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => console.log()}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl hideScroll">
                    <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">
                          {" "}
                          Route Builder{" "}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={handleClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-indigo-300">
                          Build recurring routes faster!
                        </p>
                      </div>
                    </div>

                    {!displayImport && (
                      <div
                        className="border-b border-gray-400 flex"
                        style={{
                          alignSelf: "center",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          onClick={() => setSelectedFilter("Unscheduled")}
                          style={{
                            border: "1px solid gray",
                            borderRadius: 50,
                            height: 30,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: 8,
                            cursor: "pointer",
                            color:
                              selectedFilter === "Unscheduled"
                                ? "white"
                                : "grey",
                            backgroundColor:
                              selectedFilter === "Unscheduled" ? "#4338CA" : "",
                          }}
                          className="py-4 px-4 text-sm whitespace-nowrap"
                        >
                          Unscheduled
                        </div>

                        <div style={{ marginLeft: "auto" }}>
                          {selectedFilter === "Unscheduled" && (
                            <div className="text-gray-500 py-4 px-4 text-sm whitespace-nowrap">
                              Unscheduled {unassignedCount}
                            </div>
                          )}
                          {selectedFilter === "All" && (
                            <div className="text-gray-500 py-4 px-4 text-sm whitespace-nowrap">
                              Total Customers {serviceLocationsInRoute.length}
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {!displayImport && (
                      <div className="hideScroll flex-1 divide-y divide-gray-200 overflow-y-scroll">
                        <div
                          style={{
                            marginTop: 14,
                            alignSelf: "center",
                            paddingLeft: 12,
                          }}
                        >
                          <table>
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    fontSize: 12,
                                    padding: "8px",
                                    textAlign: "left",
                                  }}
                                >
                                  Customer
                                </th>
                                <th
                                  style={{
                                    fontSize: 12,
                                    padding: "8px",
                                    textAlign: "left",
                                  }}
                                >
                                  Address
                                </th>
                                <th
                                  style={{
                                    fontSize: 12,
                                    padding: "8px",
                                    textAlign: "left",
                                  }}
                                >
                                  Subscription
                                </th>
                                <th
                                  style={{
                                    fontSize: 12,
                                    padding: "8px",
                                    textAlign: "left",
                                  }}
                                >
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {serviceLocationsInRoute
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((serviceLocation, index) => {
                                  serviceLocationCounter[
                                    serviceLocation.name +
                                      serviceLocation.recurringStopType
                                        .connectedSubscriptionId
                                  ] =
                                    (serviceLocationCounter[
                                      serviceLocation.name
                                    ] || 0) + 1;

                                  const assignedStops =
                                    serviceLocation.routes.length;

                                  // Initialize the scheduled stops counter for this location
                                  scheduledStopsCounter[serviceLocation.name] =
                                    scheduledStopsCounter[
                                      serviceLocation.name +
                                        serviceLocation.recurringStopType
                                          .connectedSubscriptionId
                                    ] || 0;

                                  let isScheduled;
                                  if (
                                    scheduledStopsCounter[
                                      serviceLocation.name
                                    ] < assignedStops
                                  ) {
                                    // If fewer iterations than assigned stops, mark as Scheduled
                                    isScheduled = true;
                                    scheduledStopsCounter[
                                      serviceLocation.name
                                    ]++;
                                  } else {
                                    // Once equal to assigned stops, mark remaining as Unscheduled
                                    isScheduled = false;
                                  }

                                  return selectedFilter === "Unscheduled" ? (
                                    isScheduled ? null : (
                                      <tr>
                                        <td
                                          style={{
                                            fontSize: 11,
                                            padding: "8px",
                                            textAlign: "left",
                                          }}
                                        >
                                          {serviceLocation.name}
                                        </td>
                                        <td
                                          style={{
                                            fontSize: 11,
                                            padding: "8px",
                                            textAlign: "left",
                                          }}
                                        >
                                          {
                                            serviceLocation.address.split(
                                              ","
                                            )[0]
                                          }
                                        </td>
                                        <td
                                          style={{
                                            fontSize: 11,
                                            padding: "8px",
                                            textAlign: "left",
                                          }}
                                        >
                                          {
                                            serviceLocation.recurringStopType
                                              .stopName
                                          }
                                        </td>
                                        <td
                                          style={{
                                            fontSize: 11,
                                            padding: "8px",
                                            textAlign: "left",
                                          }}
                                        >
                                          {isScheduled
                                            ? "Scheduled"
                                            : "Unscheduled"}
                                        </td>
                                      </tr>
                                    )
                                  ) : (
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: 11,
                                          padding: "8px",
                                          textAlign: "left",
                                        }}
                                      >
                                        {serviceLocation.name}
                                      </td>
                                      <td
                                        style={{
                                          fontSize: 11,
                                          padding: "8px",
                                          textAlign: "left",
                                        }}
                                      >
                                        {serviceLocation.address.split(",")[0]}
                                      </td>
                                      <td
                                        style={{
                                          fontSize: 11,
                                          padding: "8px",
                                          textAlign: "left",
                                        }}
                                      >
                                        {
                                          serviceLocation.recurringStopType
                                            .stopName
                                        }
                                      </td>
                                      <td
                                        style={{
                                          fontSize: 11,
                                          padding: "8px",
                                          textAlign: "left",
                                        }}
                                      >
                                        {isScheduled
                                          ? "Scheduled"
                                          : "Unscheduled"}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}

                    {displayImport && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: 60,
                        }}
                      >
                        <div style={{ width: 360, marginLeft: 20 }}>
                          <SelectServiceLocation
                            sdfsd={console.log(allServiceLocations)}
                            dsfdsf={console.log(serviceLocationsInRoute)}
                            allServiceLocations={allServiceLocations}
                            setSelectedServiceLocation={
                              setSelectedServiceLocation
                            }
                          />
                        </div>
                        {loading && (
                          <div
                            style={{
                              marginLeft: "auto",
                              marginRight: 12,
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: 40,
                              width: 40,
                            }}
                          >
                            <Rings color="blue" size={20} />
                          </div>
                        )}
                        {!loading && (
                          <div
                            onClick={handleAddLocationToRoute}
                            style={{
                              marginLeft: "auto",
                              marginRight: 12,
                              cursor: "pointer",
                            }}
                          >
                            <AiOutlinePlusCircle size={30} />
                          </div>
                        )}
                      </div>
                    )}

                    <div className="border-b border-gray-200">
                      <div className="px-6">
                        <nav
                          className="-mb-px flex space-x-6"
                          x-descriptions="Tab component"
                        ></nav>
                      </div>
                    </div>

                    {!displayImport && (
                      <div className="flex flex-shrink-0 justify-end px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={props.handleCloseRouteClick}
                        >
                          Exit Route
                        </button>

                        {props.hasUnactivatedChanges && (
                          <button
                            onClick={handleActivateRoute}
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            Activate
                          </button>
                        )}

                        {!routeIsAlreadyActive && (
                          <button
                            onClick={handleActivateRoute}
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            Activate
                          </button>
                        )}
                      </div>
                    )}

                    {displayImport && (
                      <div className="flex flex-shrink-0 justify-end px-4 py-2 absolute bottom-4 right-2">
                        <button
                          type="button"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => setDisplayImport(false)}
                        >
                          Close
                        </button>
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
