import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";

export default function ViewDetailsRec(props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.open);
    if (props.open && props.viewDetailsData) {
      console.log(props.viewDetailsData);
      console.log(props.stopDate);
    }
  }, [props.open]);

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => console.log()}>
        <div className="fixed inset-0" />\
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          {props.viewDetailsData &&
                            props.viewDetailsData.customerData && (
                              <Dialog.Title className="text-lg font-medium text-white">
                                {props.viewDetailsData.customerData.name.replace(
                                  /(^\w{1})|(\s+\w{1})/g,
                                  (letter) => letter.toUpperCase()
                                )}
                              </Dialog.Title>
                            )}

                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => handleClose()}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          {props.viewDetailsData &&
                            props.viewDetailsData.address && (
                              <p className="text-sm text-indigo-300">
                                {props.viewDetailsData.address.split(",")[0]}
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          {props.viewDetailsData &&
                            (props.viewDetailsData.type ===
                              "Reoccurring Stop" ||
                              props.viewDetailsData.type === "recStop") && (
                              <div className="space-y-6 pt-6 pb-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Subscription Title
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      disabled
                                      value={props.viewDetailsData.stopNickname}
                                      type="text"
                                      className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Employee Description
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      disabled
                                      value={
                                        props.viewDetailsData
                                          .stopWorkerDescription
                                      }
                                      type="text"
                                      className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Customer Description
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      disabled
                                      value={
                                        props.viewDetailsData
                                          .stopCustomerDescription
                                      }
                                      type="text"
                                      className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>

                                <div className="flex flex-column">
                                  {props.viewDetailsData.unscheduledDates &&
                                    !props.viewDetailsData.unscheduledDates.includes(
                                      props.stopDate
                                    ) && (
                                      <div>
                                        <div className="block text-sm font-medium text-gray-900">
                                          Date of Stop
                                        </div>
                                        <div className="mt-1 text-sm flex flex-row">
                                          <div>{props.stopDate}</div>
                                          <div className="ml-2">
                                            {dayjs(props.stopDate).format(
                                              "ddd"
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                  {props.viewDetailsData.unscheduledDates &&
                                    props.viewDetailsData.unscheduledDates.includes(
                                      props.stopDate
                                    ) && (
                                      <div>
                                        <div className="block text-sm font-medium text-gray-900">
                                          Original Stop Date
                                        </div>
                                        <div className="mt-1 text-sm flex flex-row">
                                          <div>{props.stopDate}</div>
                                          <div className="ml-2">
                                            {dayjs(props.stopDate).format(
                                              "ddd"
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                  <div className="ml-auto mr-2">
                                    <div className="block text-sm font-medium text-gray-900">
                                      Assigned Employee
                                    </div>
                                    {props.viewDetailsData.unscheduledDates &&
                                      !props.viewDetailsData.unscheduledDates.includes(
                                        props.stopDate
                                      ) && (
                                        <div className="mt-1 text-sm flex flex-row">
                                          <div>
                                            {props.viewDetailsData.contractor
                                              ? props.viewDetailsData.contractor.name.replace(
                                                  /(^\w{1})|(\s+\w{1})/g,
                                                  (letter) =>
                                                    letter.toUpperCase()
                                                )
                                              : props.viewDetailsData.employeeName.replace(
                                                  /(^\w{1})|(\s+\w{1})/g,
                                                  (letter) =>
                                                    letter.toUpperCase()
                                                )}
                                          </div>
                                        </div>
                                      )}

                                    {props.viewDetailsData.unscheduledDates &&
                                      props.viewDetailsData.unscheduledDates.includes(
                                        props.stopDate
                                      ) && (
                                        <div className="mt-1 text-sm flex flex-row">
                                          ---
                                        </div>
                                      )}
                                  </div>
                                </div>
                                {props.viewDetailsData.completedDates &&
                                  !props.viewDetailsData.completedDates.includes(
                                    props.stopDate
                                  ) && (
                                    <div className="flex flex-column">
                                      <div
                                        onClick={() => {
                                          props.handleOpenReschedule(
                                            props.viewDetailsData
                                          );
                                        }}
                                        style={{
                                          color: "blue",
                                          borderBottom: "1px solid blue",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Reschedule Stop
                                      </div>
                                    </div>
                                  )}
                                {props.viewDetailsData.unscheduledDates &&
                                  !props.viewDetailsData.unscheduledDates.includes(
                                    props.stopDate
                                  ) && (
                                    <div>
                                      {props.viewDetailsData.completedDates &&
                                        !props.viewDetailsData.completedDates.includes(
                                          props.stopDate
                                        ) && (
                                          <div className="flex flex-column">
                                            <div
                                              onClick={() =>
                                                props.handleCompleteRecTimeStop(
                                                  props.viewDetailsData,
                                                  props.stopDate,
                                                  props.viewDetailsData
                                                    .contractor.id
                                                )
                                              }
                                              style={{
                                                color: "blue",
                                                borderBottom: "1px solid blue",
                                                cursor: "pointer",
                                              }}
                                            >
                                              Complete Stop
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  )}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-start px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() =>
                          props.handleViewDay(
                            props.dayData[props.viewDetailsData.contractor.id],
                            props.viewDetailsData.contractor,
                            props.viewDetailsData.completedDates
                          )
                        }
                      >
                        View Route
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
