import { useEffect, useState } from "react";
import { IoLocationSharp } from "react-icons/io5";
import ServiceLocationSubscriptionCard from "./ServiceLocationSubscriptionCard";
import firebase from "firebase/compat/app";
import { confirmAlert } from "react-confirm-alert";

export default function ServiceLocationSubscriptions(props) {
  const db = firebase.firestore();
  const [activeSubscription, setActiveSubscription] = useState();
  const [pausedSubscriptions, setPausedSubscriptions] = useState([]);
  const [reload, setReload] = useState(false);
  //scheduledSubscription: firebase.firestore.FieldValue.delete(),
  //pausedSubscriptions: pausedSubscriptions,

  useEffect(() => {
    db.collection("Customers")
      .doc(props.serviceLocation.customerId)
      .collection("ServiceLocations")
      .doc(props.serviceLocation.serviceLocationId)
      .onSnapshot((snapshot) => {
        const data = snapshot.data();
        console.log(data);
        setActiveSubscription(data.subscriptions);
        setPausedSubscriptions(data.pausedSubscriptions || []);
      });
  }, []);

  const handlePauseSubscription = (activeSubscription) => {
    confirmAlert({
      title: "Please confirm!",
      message:
        "Are you sure you want to pause this subscription? It will remove all currently scheduled recurring stops for this service location.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            firebase
              .auth()
              .currentUser.getIdToken()
              .then(async (token) => {
                await fetch(
                  "https://us-central1-symbri-production.cloudfunctions.net/handlePauseSubscription",
                  {
                    method: "POST",
                    body: JSON.stringify({
                      token: token,
                      serviceLocationId:
                        props.serviceLocation.serviceLocationId,
                      customerId: props.serviceLocation.customerId,
                      subscriptionId: activeSubscription.subscriptionId,
                      subscriptionStopId: activeSubscription.subscriptionStopId,
                    }),
                  }
                );

                setReload(!reload);
              });
          },
        },
        {
          label: "No",
          onClick: () => console.log("Click No"),
        },
      ],
    });
  };

  const handleDeleteSubscription = async (
    subscriptionId,
    subscriptionStopId
  ) => {
    const token = await firebase.auth().currentUser.getIdToken();

    await fetch(
      "https://us-central1-symbri-production.cloudfunctions.net/handleDeleteSubscription",
      {
        method: "POST",
        body: JSON.stringify({
          token: token,
          serviceLocationId: props.serviceLocation.serviceLocationId,
          customerId: props.serviceLocation.customerId,
          subscriptionId: subscriptionId,
          subscriptionStopId: subscriptionStopId,
        }),
      }
    );

    setReload(!reload);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-0">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg border-gray-200 border-2">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr className="flex justify-between">
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 flex-shrink"
                    >
                      Subscriptions
                    </th>
                    <th
                      onClick={() => props.setAddSubscriptionDisplay(true)}
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-right text-[blue] text-sm font-semibold text-gray-900 sm:pl-6 flex-shrink cursor-pointer"
                    >
                      + Add Subscription
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white customer-profile-tbody">
                  {activeSubscription &&
                    activeSubscription.map((activeSubscription) => (
                      <ServiceLocationSubscriptionCard
                        businessId={props.businessId}
                        subscriptionDetails={activeSubscription}
                        paused={activeSubscription.paused}
                        serviceLocation={props.serviceLocation}
                        handlePauseSubscription={() =>
                          handlePauseSubscription(activeSubscription)
                        }
                        handleResumeSubscription={
                          props.handleResumeSubscription
                        }
                        handleReloadData={props.handleReloadData}
                        cancelScheduled={props.cancelScheduled}
                        handleDeleteSubscription={handleDeleteSubscription}
                        handleViewSubscription={props.handleViewSubscription}
                        reloadCards={reload}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
