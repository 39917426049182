/* This example requires Tailwind CSS v2.0+ */

import React, { useEffect, useState, useRef } from "react";
import firebase from "firebase/compat/app";
import { BiUser } from "react-icons/bi";
import { ImProfile } from "react-icons/im";
import { MdSendToMobile } from "react-icons/md";
import CustomerProfile from "./CustomerProfile";
import CustomersButton from "./CustomersButton";
import AddCustomer from "./AddCustomer";
import ImportCustomers from "./ImportCustomers";
import { toast } from "react-toastify";

export default function Customers(props) {
  const selectedBusiness = props.businessId;
  const [allCustomers, setAllCustomers] = useState([]);
  const [addCustomerDisplay, setAddCustomerDisplay] = useState(false);
  const [importCustomersDisplay, setImportCustomersDisplay] = useState(false);
  const [mainDisplay, setMainDisplay] = useState(true);
  const [selectedCustomerData, setSelectedCustomerData] = useState();
  const [lastVisibleStack, setLastVisibleStack] = useState([]);
  const [firstVisibleStack, setFirstVisibleStack] = useState([]);
  const [backEnabled, setBackEnabled] = useState(false);
  const [disablePagination, setDisablePagination] = useState(false);
  const [customerCount, setCustomerCount] = useState();
  const isMounted = useRef();

  const db = firebase.firestore();

  useEffect(() => {
    isMounted.current = true;

    const handleAsync = async () => {
      if (isMounted.current) {
        const snapshot = await getCustomers(null);

        const customers = snapshot.docs.map((doc) => doc.data());

        const lastVisible = snapshot.docs[snapshot.docs.length - 1];

        setAllCustomers(customers);

        setLastVisibleStack((prevStack) => [...prevStack, lastVisible]);

        const businessDoc = await db
          .collection("Businesses")
          .doc(selectedBusiness)
          .get();
        setCustomerCount(businessDoc.data().customerCount);
      }
    };

    handleAsync();

    if (props.preSelectedCustomer) {
      setMainDisplay(false);
      setSelectedCustomerData(props.preSelectedCustomer);
    }

    return () => {
      isMounted.current = false;
      stopInitialListener();
    };
  }, [selectedBusiness]);

  let initialListener = null;

  const getCustomers = (lastVisible) => {
    let query = db
      .collection("Customers")
      .where("businessIds", "array-contains", selectedBusiness)
      .orderBy("customerFirstName")
      .limit(20);

    if (lastVisible) {
      query = query.startAfter(lastVisible);
    } else {
      initialListener = query.onSnapshot((snapshot) => {
        const customers = snapshot.docs.map((doc) => doc.data());

        setAllCustomers(customers);
        setCustomerCount(customers.length);
      });
    }

    return query.get();
  };

  const stopInitialListener = () => {
    if (initialListener) {
      initialListener();
      initialListener = null;
    }
  };

  const handleNext = async () => {
    stopInitialListener();

    const lastVisibleState = lastVisibleStack[lastVisibleStack.length - 1];
    const snapshot = await getCustomers(lastVisibleState);

    if (snapshot.empty) {
      return;
    }

    const customers = snapshot.docs.map((doc) => doc.data());

    const firstVisible = snapshot.docs[0];
    const lastVisibleSnapshot = snapshot.docs[snapshot.docs.length - 1];

    setAllCustomers(customers);

    setFirstVisibleStack((prevStack) => [...prevStack, firstVisible]);
    setLastVisibleStack((prevStack) => [...prevStack, lastVisibleSnapshot]);
    setBackEnabled(true);
  };

  const handleBack = async () => {
    const newFirstVisibleStack = [...firstVisibleStack];
    const previousFirstVisible = newFirstVisibleStack.pop();
    setFirstVisibleStack(newFirstVisibleStack);

    const newLastVisibleStack = [...lastVisibleStack];
    newLastVisibleStack.pop();
    setLastVisibleStack(newLastVisibleStack);

    const firstVisible =
      newFirstVisibleStack.length > 0
        ? newFirstVisibleStack[newFirstVisibleStack.length - 1]
        : null;

    if (previousFirstVisible) {
      const snapshot = await getCustomers(firstVisible);

      const customers = snapshot.docs.map((doc) => doc.data());

      setAllCustomers(customers);
    }

    if (newFirstVisibleStack.length === 0) {
      setBackEnabled(false);
      // restart initial listener since we're back at the start
      getCustomers(null);
    } else {
      stopInitialListener();
    }
  };

  const searchCustomers = (term) => {
    const firstNameTerm = term.split(" ")[0].toLowerCase().trim();
    let query = db
      .collection("Customers")
      .where("businessIds", "array-contains", selectedBusiness)
      .orderBy("customerFirstName")
      .startAt(firstNameTerm)
      .endAt(firstNameTerm + "\uf8ff") // Ensures that the query includes all possible strings that start with `firstNameTerm`
      .limit(20);

    return query.get();
  };

  const handleSearch = async (term) => {
    if (term.trim() !== "") {
      //here
      setDisablePagination(true);
      const snapshot = await searchCustomers(term);

      const customers = snapshot.docs.map((doc) => doc.data());

      setAllCustomers(customers);
    } else {
      const snapshot = await getCustomers(null);

      const customers = snapshot.docs.map((doc) => doc.data());

      // Save the first and last documents of the current page
      const lastVisible = snapshot.docs[snapshot.docs.length - 1];

      setAllCustomers(customers);

      // Push the current lastVisible to the stack, not the firstVisible
      setLastVisibleStack((prevStack) => [...prevStack, lastVisible]);
      setDisablePagination(false);
    }
  };

  useEffect(() => {
    handleSearch(props.searchTerm);
  }, [props.searchTerm]);

  const handleCustomerClick = (data) => {
    setSelectedCustomerData(data);
    setMainDisplay(false);
    props.handleAddToBreadcrumb({
      name: (data.customerFirstName + " " + data.customerLastName).replace(
        /(^\w{1})|(\s+\w{1})/g,
        (letter) => letter.toUpperCase()
      ),
      type: "customer",
      current: true,
      value: data,
    });
  };

  const handleMainButtonClick = (menuOption) => {
    if (menuOption === "New Customer") {
      setAddCustomerDisplay(true);
    } else if (menuOption === "Import Customers") {
      setImportCustomersDisplay(true);
    } else if (menuOption === "Invite All Customers") {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then((token) => {
          fetch(
            "https://us-central1-symbri-production.cloudfunctions.net/inviteAllCustomers",
            {
              method: "POST",
              body: JSON.stringify({
                token: token,
              }),
            }
          );
          toast.success("All customers will be sent invites!");
        });
    }
  };

  const handleResendInvite = (data) => {
    console.log("+1" + data.customerPhoneNumber);
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((token) => {
        fetch(
          "https://us-central1-symbri-production.cloudfunctions.net/sendCustomerGreeting",
          {
            method: "POST",
            body: JSON.stringify({
              customerPhoneNumber: "+1" + data.customerPhoneNumber,
              customerEmailAddress: data.customerEmailAddress,
              customerFirstName: data.customerFirstName,
              selectedBusiness: selectedBusiness,
              token: token,
              inviteCode: data.inviteCode,
            }),
          }
        ).then(() => {
          toast.success(
            "A text message has been sent to the customer inviting them!"
          );
        });
      });
  };

  return mainDisplay ? (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Customers {customerCount}
          </h1>
          <p className="mt-2 text-sm text-gray-700"></p>
        </div>

        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
          <CustomersButton
            handleMainButtonClick={handleMainButtonClick}
            businessId={selectedBusiness}
          />
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <button
                          disabled={!backEnabled || disablePagination}
                          onClick={handleBack}
                          type="button"
                          className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto mr-4"
                        >
                          Back
                        </button>
                        <button
                          disabled={disablePagination}
                          onClick={handleNext}
                          type="button"
                          className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                        >
                          Next
                        </button>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {allCustomers.map((person) => (
                    <tr key={person.name}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0">
                            <BiUser className="h-10 w-10 rounded-full" />
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">
                              {(
                                person.customerFirstName +
                                " " +
                                person.customerLastName
                              ).replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                                letter.toUpperCase()
                              )}
                            </div>
                            <div className="text-gray-500">
                              {person.customerHomeAddress.replace(
                                /(^\w{1})|(\s+\w{1})/g,
                                (letter) => letter.toUpperCase()
                              )}
                            </div>
                          </div>
                        </div>
                      </td>
                      {!person.pending && (
                        <td className="flex justify-center items-center h-20 ml-auto">
                          <ImProfile
                            onClick={() => handleCustomerClick(person)}
                            size={20}
                            className="ml-auto cursor-pointer mr-8"
                          />
                        </td>
                      )}
                      {person.pending && (
                        <td className="flex justify-center items-center h-20">
                          <MdSendToMobile
                            onClick={() => handleResendInvite(person)}
                            size={20}
                            className="ml-auto cursor-pointer mr-8"
                          />
                          <ImProfile
                            onClick={() => handleCustomerClick(person)}
                            size={20}
                            className="cursor-pointer mr-8"
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <AddCustomer
        businessId={selectedBusiness}
        open={addCustomerDisplay}
        setOpen={setAddCustomerDisplay}
        userPermissions={props.userPermissions}
      />
      <ImportCustomers
        businessId={selectedBusiness}
        open={importCustomersDisplay}
        setOpen={setImportCustomersDisplay}
      />
    </div>
  ) : (
    <CustomerProfile
      handleAddToBreadcrumb={props.handleAddToBreadcrumb}
      businessId={selectedBusiness}
      selectedCustomerData={selectedCustomerData}
    />
  );
}
