import { Fragment, useEffect, useState, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import firebase from "firebase/compat/app";
import { generateBusinessId } from "../Tools/generateBusinessId";
import SelectSubscription from "./SelectSubscription";
import { toast } from "react-toastify";
import SelectStartDate from "./SelectStartDate";
import dayjs from "dayjs";
import Geocoder from "react-native-geocoding";
import SelectEmployeeSearch from "./SelectEmployeeSearch";

export default function ServiceLocationAddOneTimeStop(props) {
  const [open, setOpen] = useState(false);
  const selectedBusiness = props.selectedBusiness;
  const selectedCustomer = props.selectedCustomer;
  const [selectedDate, setSelectedDate] = useState();
  const [price, setPrice] = useState("0");
  const selectedServiceLocation = props.selectedServiceLocation;
  const db = firebase.firestore();
  const [allEmployees, setAllEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [stopDescription, setStopDescription] = useState("");
  const [stopName, setStopName] = useState("");

  useEffect(() => {
    setOpen(props.open);
    if (props.open) {
      db.collection("Businesses")
        .doc(selectedBusiness)
        .collection("Workers")
        .get()
        .then((snapshot) => {
          let workers = [];
          for (let index = 0; index < snapshot.docs.length; index++) {
            const doc = snapshot.docs[index].data();
            workers.push(doc);
          }
          setAllEmployees(workers);
        });
    }
  }, [props.open]);

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleAddJob = () => {
    if (!selectedEmployee) {
      alert("Please select an employee");
      return;
    }

    // check if valid date selected

    if (!selectedDate) {
      alert("Please select a date");
    }

    if (stopName === "") {
      alert("Please enter a stop name");
      return;
    }

    if (stopDescription === "") {
      alert("Please enter a stop description");
      return;
    }

    const newStopId = generateBusinessId();

    const stopDate = selectedDate;

    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Routes")
      .where("active", "==", true)
      .get()
      .then(async (routeQuery) => {
        const routeId = routeQuery.docs[0].id;
        Geocoder.from(selectedServiceLocation.address)
          .then(async (json) => {
            console.log(json.results[0].geometry.location);
            const stopData = {
              dayOfTheWeek: dayjs(stopDate).format("dddd"),
              frequency: "once",
              startOn: dayjs(stopDate).format("MM/DD/YYYY"),
              stopAfter: "no end",
              name: stopName,
              address: selectedServiceLocation.address,
              stopId: newStopId,
              routeId: routeId,
              serviceLocationId: selectedServiceLocation.serviceLocationId,
              completedDates: [],
              contractor: {
                id: selectedEmployee.uid,
                name: selectedEmployee.name,
                profilePhoto: selectedEmployee.profilePhoto,
              },
              customerData: {
                name: selectedServiceLocation.name,
                customerId: selectedServiceLocation.customerId,
                businessId: selectedBusiness,
              },
              businessId: selectedBusiness,
              stopNickname: stopName,
              location: json.results[0].geometry.location,
              stopTemplateId: "none",
              stopCustomerDescription: stopName,
              stopWorkerDescription: stopDescription,
              stopDate: dayjs(stopDate).format("MM/DD/YYYY"),
              type: "One Time Stop",
              messageId: "none",
            };

            console.log(stopData);

            const routeData = {
              frequency: "once",
              name: stopName,
              address: selectedServiceLocation.address,
              stopObject: stopData,
              routeId: stopData.routeId, // change
              serviceLocationId: selectedServiceLocation.serviceLocationId,
              type: "One Time Stop",
              completed: false,
              stopId: stopData.stopId,
              contractor: selectedEmployee,
              location: json.results[0].geometry.location,
              customerData: {
                name: selectedServiceLocation.name,
                customerId: selectedServiceLocation.customerId,
                businessId: selectedBusiness,
              },
            };

            console.log(routeData);

            db.collection("Businesses")
              .doc(selectedBusiness)
              .collection("Workers")
              .doc(selectedEmployee.uid)
              .collection("Route")
              .doc("JobStops")
              .set(
                {
                  route: firebase.firestore.FieldValue.arrayUnion(routeData),
                  checkDistanceTime: true,
                },
                { merge: true }
              );

            // add to customer service location routes

            await db
              .collection("Businesses")
              .doc(selectedBusiness)
              .collection("Workers")
              .doc(selectedEmployee.uid)
              .update({ lastUpdated: dayjs().unix() });

            db.collection("Businesses")
              .doc(selectedBusiness)
              .get()
              .then((documentSnapshot) => {
                const businessData = documentSnapshot.data();

                firebase
                  .auth()
                  .currentUser.getIdToken()
                  .then(async (token) => {
                    fetch(
                      "https://us-central1-symbri-production.cloudfunctions.net/sendCustomerNotification",
                      {
                        method: "POST",
                        body: JSON.stringify({
                          token: token,
                          notificationData: {
                            hasApp: {
                              body:
                                "A new one time stop named " +
                                stopName +
                                " has been created!.",
                              title: "One Time stop created!",
                            },
                            noApp: {
                              body:
                                "A new stop named " +
                                stopName +
                                " has been created!. Please download the app and login into view the the stop details and message " +
                                businessData.name +
                                " with any questions.",
                              title: "One Time stop created!",
                            },
                          },
                          customerId: selectedCustomer.customerId,
                        }),
                      }
                    );
                    await db
                      .collection("Businesses")
                      .doc(selectedBusiness)
                      .collection("Routes")
                      .doc(routeId)
                      .update({
                        reload: !routeQuery.docs[0].data().reload,
                      });
                  });
                handleClose();
                toast.success("One Time Stop Added");
              });
          })
          .catch((e) => console.log(e));
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => console.log()}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            Add One Time Stop
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => handleClose()}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Get started by filling in the one time stop details
                            below.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Stop Title
                              </label>
                              <div className="mt-1">
                                <input
                                  onChange={(change) =>
                                    setStopName(change.target.value)
                                  }
                                  value={stopName}
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10 pl-2"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Stop Description
                              </label>
                              <div className="mt-1">
                                <input
                                  onChange={(change) =>
                                    setStopDescription(change.target.value)
                                  }
                                  value={stopDescription}
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10 pl-2"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Employee
                              </label>
                              <div className="mt-1">
                                <SelectEmployeeSearch
                                  businessId={selectedBusiness}
                                  setSelectedEmployee={setSelectedEmployee}
                                  employees={allEmployees}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Stop Date
                              </label>
                              <div className="mt-1">
                                <input
                                  onChange={(change) =>
                                    setSelectedDate(change.target.value)
                                  }
                                  type="date"
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => handleClose(false)}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleAddJob}
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
